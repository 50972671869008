import hashQueryParamService from '@services/hash-query-param-service.ts';

class AnchorService {
	scrollTo(elementId: string) {
		const anchorId = hashQueryParamService.get().anchor;

		if (!anchorId || anchorId !== elementId) {
			return;
		}

		const element = document.getElementById(elementId);

		if (element) {
			element.scrollIntoView({
				behavior: 'auto',
				block: 'center',
			});
		}
	}

	isAnchored(elementId: string) {
		const anchorId = hashQueryParamService.get().anchor;

		return elementId === anchorId;
	}
}

const anchorService = new AnchorService();
export default anchorService;
