import { z } from 'zod';

export enum CreatorType {
	USER = 'User',
	GROUP = 'Group',
}

export interface Creator {
	id: number;
	name: string;
	type: CreatorType;
	is_verified: boolean;
	thumbnail_hash: string;
	place_ids: number[];
}

export const CreatorSchema: z.ZodType<Creator> = z.object({
	id: z.number(),
	name: z.string(),
	type: z.nativeEnum(CreatorType),
	is_verified: z.boolean(),
	thumbnail_hash: z.string(),
	place_ids: z.array(z.number()),
});

export const CreatorListSchema: z.ZodType<Creator[]> = z.array(CreatorSchema);
