export enum IconEnum {
	DELETE = 'delete',
	EXTERNAL_LINK = 'open_in_new',
	ARROW_BACK = 'arrow_back',
	ARROW_FORWARD = 'arrow_forward',
	EDIT = 'edit',
	SEARCH = 'search',
	CHEVRON_LEFT = 'chevron_left',
	CHEVRON_RIGHT = 'chevron_right',
	CHEVRON_UP = 'expand_less',
	CHEVRON_DOWN = 'expand_more',
	CALENDAR = 'calendar_month',
	CLEAR = 'clear',
	CONFIRM = 'check',
	MENU = 'menu',
	BULK_ADD = 'post_add',
	TAG = 'bookmark_border',
	PLACE = 'sports_esports',
	ROBUX = 'paid',
	BADGE = 'local_police',
	DEEP_LINK = 'link',
	ACCOUNT = 'account_circle',
	HAND = 'hand',
	LOGOUT = 'logout',
	SAD = 'sad',
	GROUP = 'group',
	USER = 'user',
	MORE = 'more',
	TOOLS = 'handyman',
	THE_GANG_BLACK = 'the_gang_black',
	THE_GANG_WHITE = 'the_gang_white',
	OVERVIEW = 'overview',
	BREADCRUMB_ARROW = 'breadcrumb_arrow',
	ADD = 'add_circle_outline',
	SHARE = 'share',
	ROUND_CHECK = 'check_circle',
	ROUND_INFO = 'info',
	ROUND_WARNING = 'error',
	ROUND_ERROR = 'cancel',
	COPY = 'content_copy',
	LIGHT_MODE = 'light_mode',
	DARK_MODE = 'dark_mode',
	PLAY = 'play_circle_outline',
	DESCRIPTION = 'description',
	VISIBILITY = 'visibility',
	VISIBILITY_OFF = 'visibility_off',
	DISABLED = 'do_disturb_on',
	COMPARE = 'compare_arrows',
	MANAGE_ACCOUNTS = 'manage_accounts',
	CHECK_CIRCLE_OUTLINE = 'task_alt',
	HOME = 'home',
	HELP = 'help',
	CURATED = 'workspace_premium',
	DAILY_PICKS = 'auto_awesome',
	VIDEO = 'smart_display',
}
