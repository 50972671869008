import experienceStore from '@store/experience-store.ts';
import thumbnailStore from '@store/thumbnail-store.ts';
import viewStore from '@store/view-store.ts';

export interface ExperienceDetailsLoaderReturn {
	experienceId: string;
}

export async function ExperienceDetailsLoader(experienceId: string) {
	viewStore.loadingViewData = true;

	if (experienceStore.getFullExperienceById(+experienceId)) {
		void experienceStore.getFullExperience(experienceId);
		return { experienceId };
	}

	const res = await experienceStore.getFullExperience(experienceId);

	if (!res.ok) {
		if (!experienceStore.getFullExperienceById(+experienceId)) {
			throw new Response(null, { status: res.status });
		}
	}

	void thumbnailStore.loadAllThumbnails(+experienceId);

	return { experienceId };
}
