import { observer } from 'mobx-react-lite';
import TagExpansionInput from '@components/data/tag-expansion-input/tag-expansion-input.tsx';
import Button from '@components/core/button/button.tsx';
import { BUTTON_STYLE } from '@components/core/button/button-style-enum.ts';
import { useState } from 'react';
import { PlaceTag } from '@/schemas/plage-tag-schema.ts';
import placeTagStore from '@store/place-tag-store.ts';
import { Experience } from '@/schemas/experience-schema.ts';
import ClassString from '@utils/class-string.ts';

interface ExperienceDetailsTagAdderProps {
	experienceTags: PlaceTag[];
	experience: Experience;
	className?: string;
}

const ExperienceDetailsTagAdder = observer(function ExperienceDetailsTagAdder(
	props: ExperienceDetailsTagAdderProps
) {
	const [selectedTag, setSelectedTag] = useState<PlaceTag | undefined>(
		undefined
	);
	const [searchTerm, setSearchTerm] = useState('');

	const addTag = () => {
		if (selectedTag) {
			void placeTagStore.addPlaceIdToTag(
				selectedTag.id,
				props.experience.place_id
			);
			setSelectedTag(undefined);
			setSearchTerm('');
		}
	};

	return (
		<div
			className={ClassString({
				static: 'flex flex-col justify-between items-start h-full',
				dynamic: {},
				custom: props.className,
			})}
		>
			<div className={'text-2xl mb-2'}>{'Add Curated Tag'}</div>

			<div
				className={
					'flex justify-center items-center gap-4 w-full flex-1'
				}
			>
				<TagExpansionInput
					searchTerm={searchTerm}
					setSearchTerm={setSearchTerm}
					tag={selectedTag}
					setTag={setSelectedTag}
					filteredTagIds={props.experienceTags.map((tag) => tag.id)}
					excludeNonGlobal
					scroll
				/>

				<Button
					title={'Add Tag'}
					disabled={!selectedTag}
					onClick={addTag}
					buttonStyle={BUTTON_STYLE.PRIMARY}
				/>
			</div>

			<div></div>
		</div>
	);
});

export default ExperienceDetailsTagAdder;
