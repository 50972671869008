import placeTagStore from '@store/place-tag-store.ts';

export async function ExperienceTagsLoader() {
	if (!placeTagStore.initialized) {
		await placeTagStore.waitForInit();
	} else {
		void placeTagStore.loadPlaceTags();
	}

	return placeTagStore.placeTags;
}
