import { observer } from 'mobx-react-lite';
import NavigationButton from '@components/navigation/navigation-button/navigation-button.tsx';
import { NavigationButtonEnum } from '@components/navigation/navigation-button/navigation-button-enum.ts';
import { Experience } from '@/schemas/experience-schema.ts';
import PlaceThumbnail from '@components/data/place-thumbnail/place-thumbnail.tsx';
import { PlaceThumbnailSize } from '@components/data/place-thumbnail/place-thumbnail-constants.ts';
import TGSTable, { CustomColDef } from '@components/core/table/table.tsx';
import { CustomCellRendererProps } from 'ag-grid-react';
import { ValueFormatterParams } from 'ag-grid-community';
import formatNumberWithSuffix from '@utils/format-number-with-suffix.ts';
import { useEffect } from 'react';
import thumbnailStore from '@store/thumbnail-store.ts';
import { ThumbnailType } from '@/schemas/thumbnail-schema.ts';

interface PlacesTableProps {
	experiences: Experience[];
}

const numberFormatter = new Intl.NumberFormat(navigator.language);

const columnDefs: CustomColDef<Experience>[] = [
	{
		minWidth: 55,
		maxWidth: 55,
		cellRenderer: (params: CustomCellRendererProps) => {
			const placement = (params.node.rowIndex ?? 0) + 1;
			return (
				<div
					className={
						'text-2xl font-bold flex justify-center items-center'
					}
				>
					{placement}
				</div>
			);
		},
		cellStyle: {
			display: 'flex',
			alignItems: 'center',
		},
		resizable: false,
		sortable: false,
		context: {
			priority: 1,
		},
	},
	{
		minWidth: 40,
		maxWidth: 40,
		cellRenderer: (params: CustomCellRendererProps) => {
			return (
				<PlaceThumbnail
					place={params.node.data as Experience}
					size={PlaceThumbnailSize.VERY_SMALL}
					className={'rounded-md'}
				/>
			);
		},
		cellStyle: {
			display: 'flex',
			alignItems: 'center',
			paddingLeft: '0',
			paddingRight: '0',
		},
		resizable: false,
		sortable: false,
		context: {
			priority: 1,
		},
	},
	{
		headerName: 'Name',
		field: 'name',
		resizable: false,
		cellRenderer: (params: CustomCellRendererProps) => {
			const place = params.node.data as Experience;
			return (
				<NavigationButton
					path={`/experiences/${place.place_id}`}
					direction={NavigationButtonEnum.FORWARD}
					title={place.name}
				/>
			);
		},
		cellStyle: {
			display: 'flex',
			alignItems: 'center',
			paddingLeft: '25px',
			paddingRight: '0',
		},
		sortable: false,
		minWidth: 300,
		context: {
			priority: 1,
		},
		flex: 1,
	},
	{
		headerName: 'Players Online',
		field: 'players_online',
		resizable: false,
		valueFormatter: (params: ValueFormatterParams<Experience, number>) => {
			return numberFormatter.format(params.value ?? 0);
		},
		cellStyle: {
			display: 'flex',
			alignItems: 'center',
		},
		sortable: false,
		minWidth: 150,
		maxWidth: 150,
		context: {
			priority: 2,
		},
	},
	{
		headerName: 'Total Visits',
		field: 'total_visits',
		resizable: false,
		valueFormatter: (params: ValueFormatterParams<Experience, number>) => {
			return formatNumberWithSuffix(params.value ?? 0);
		},
		cellStyle: {
			display: 'flex',
			alignItems: 'center',
		},
		sortable: false,
		minWidth: 150,
		maxWidth: 150,
		context: {
			priority: 2,
		},
	},
	{
		headerName: 'Like Percentage',
		field: 'like_percentage',
		resizable: false,
		valueFormatter: (params: ValueFormatterParams<Experience, number>) => {
			const likePercentage = params.value ?? 0;
			return `${(likePercentage * 100).toFixed(2)}%`;
		},
		cellStyle: {
			display: 'flex',
			alignItems: 'center',
		},
		sortable: false,
		minWidth: 150,
		maxWidth: 150,
		context: {
			priority: 2,
		},
	},
];

const ExperienceTable = observer(function PlacesTable(props: PlacesTableProps) {
	useEffect(() => {
		void thumbnailStore.loadThumbnails(
			props.experiences,
			ThumbnailType.ICON
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<TGSTable
			columnDefs={columnDefs}
			rowData={props.experiences}
			tableName={'place-table'}
			rowHeight={50}
			disablePagination
		/>
	);
});

export default ExperienceTable;
