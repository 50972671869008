import default1 from '@assets/roblox-banner-default-1.webp';
import default2 from '@assets/roblox-banner-default-2.webp';
import default3 from '@assets/roblox-banner-default-3.webp';
import default4 from '@assets/roblox-banner-default-4.webp';

export enum PlaceBannerSize {
	VERY_SMALL = '160/100',
	SMALL = '256/144',
	MEDIUM = '384/216',
	LARGE = '576/324',
	VERY_LARGE = '768/432',
}

export const DefaultBanners = [default1, default2, default3, default4];
