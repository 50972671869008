import { useEffect, useState } from 'react';
import { HashParamMap, stringToHashParamKey } from '@/enums/hash-params.ts';

const useHashQueryParams = (): [
	HashParamMap,
	(newParams: HashParamMap) => void,
] => {
	const getHashParams = (): HashParamMap => {
		const hashParams = new URLSearchParams(window.location.hash.slice(1));
		const params: HashParamMap = {};

		hashParams.forEach((value, key) => {
			const typedKey = stringToHashParamKey(key);

			params[typedKey] = value;
		});

		return params;
	};

	const setHashParams = (newParams: HashParamMap): void => {
		const hashParams = new URLSearchParams();

		for (const key in newParams) {
			if (Object.prototype.hasOwnProperty.call(newParams, key)) {
				const typedKey = stringToHashParamKey(key);

				if (newParams[typedKey]) {
					hashParams.set(key, newParams[typedKey]);
				}
			}
		}

		window.location.hash = hashParams.toString();
	};

	const [params, setParams] = useState<HashParamMap>(getHashParams);

	useEffect(() => {
		const handleHashChange = () => {
			setParams(getHashParams());
		};

		window.addEventListener('hashchange', handleHashChange);

		return () => {
			window.removeEventListener('hashchange', handleHashChange);
		};
	}, []);

	return [params, setHashParams];
};

export default useHashQueryParams;
