import Highcharts from 'highcharts';
import hashQueryParamService from '@services/hash-query-param-service.ts';
import getGraphId from '@utils/get-graph-id.ts';

const setHashExtremes = (chart: Highcharts.Chart) => {
	const hashParams = hashQueryParamService.get();
	if (
		chart.options.title?.text &&
		hashParams.anchor === getGraphId(chart.options.title.text) &&
		hashParams.graphMin &&
		hashParams.graphMax
	) {
		const min = +hashParams.graphMin;
		const max = +hashParams.graphMax;
		chart.xAxis.forEach((axis) => {
			axis.setExtremes(min, max, true, false);
		});
	}
};

export default setHashExtremes;
