import { observer } from 'mobx-react-lite';
import logo from '@assets/logo_white.svg';
import logoSmall from '@assets/square_logo_white.svg';
import ExpansionPanel from '@components/core/expansion-panel/expansion-panel.tsx';
import { IconEnum } from '@components/core/icon/icon-enum.ts';
import NavSidebarLink from '@components/navigation/nav/nav-sidebar-link.tsx';
import { NavLink, useNavigate } from 'react-router-dom';
import ClassString from '@utils/class-string.ts';
import navSidebarStore from '@store/nav-sidebar-store.ts';
import useOnClickOutside from '@hooks/use-on-click-outside.tsx';
import Icon from '@components/core/icon/icon.tsx';
import authStore from '@store/auth-store.ts';
import { UserPermission } from '@/schemas/user-schema.ts';
import themeStore from '@store/theme-store.ts';
import { useState } from 'react';
import GlobalExperienceSearch from '@components/core/global-experience-search/global-experience-search.tsx';
import localStorageService from '@services/local-storage-service.ts';
import ThemeSelector from '@components/core/theme-selector/theme-selector.tsx';

enum NavBarMenuKey {
	EXPERIENCES = 'experiences',
	TOOLS = 'tools',
	COMPARE = 'compare',
}

const inCompareView = () => {
	return window.location.href.includes('/compare');
};

const compareOpen = () => {
	return inCompareView() || menuOpen(NavBarMenuKey.COMPARE);
};

const inToolsView = () => {
	return (
		window.location.href.includes('/robux') ||
		window.location.href.includes('/deep-link') ||
		window.location.href.includes('/event-credentials')
	);
};

const toolsOpen = () => {
	return inToolsView() || menuOpen(NavBarMenuKey.TOOLS);
};

const storeMenuOpen = (menuKey: NavBarMenuKey, open: boolean) => {
	return localStorageService.set(
		`tgs-nbm-o-${menuKey}`,
		open ? 'true' : 'false',
		authStore.user?.id
	);
};

const menuOpen = (menuKey: NavBarMenuKey) => {
	return (
		localStorageService.get(`tgs-nbm-o-${menuKey}`, authStore.user?.id) ===
		'true'
	);
};

const NavSidebar = observer(function NavSidebar() {
	const { ref } = useOnClickOutside<HTMLElement>(() => {
		navSidebarStore.setOpen(false);
	});

	const navigate = useNavigate();

	const [minimizeOnMouseLeave, setMinimizeOnMouseLeave] = useState(false);

	const logoutHandler = async () => {
		navSidebarStore.setOpen(false);
		await authStore.logOut();
		navigate('/login');
	};

	return (
		<aside
			className={ClassString({
				static: '-left-sidebar lg:left-0 px-4 w-sidebar bg-gray-600 fixed -mt-nav h-screen flex flex-col z-nav-sidebar overflow-y-auto overflow-x-hidden',
				dynamic: {
					'!left-0': navSidebarStore.open,
					'!-left-sidebar': !authStore.isLoggedIn,
					'lg:w-24': navSidebarStore.minimal,
				},
			})}
			style={{
				transition:
					'left 400ms cubic-bezier(0.165, 0.84, 0.44, 1), visibility 0ms ease 400ms, width 400ms cubic-bezier(0.165, 0.84, 0.44, 1)',
			}}
			ref={ref}
			onMouseLeave={() => {
				if (minimizeOnMouseLeave) {
					navSidebarStore.setMinimal(true);
					setMinimizeOnMouseLeave(false);
				}
			}}
		>
			<div
				className={ClassString({
					static: 'py-6 h-32 flex justify-center items-center',
					dynamic: { 'lg:items-start': navSidebarStore.minimal },
				})}
			>
				<NavLink to={'/overview'} draggable="false">
					<img
						src={logo}
						alt={'logo-full'}
						className={ClassString({
							static: 'w-full max-w-lg px-6',
							dynamic: { 'lg:hidden': navSidebarStore.minimal },
						})}
						draggable="false"
					/>

					<img
						src={logoSmall}
						alt={'logo-small'}
						className={ClassString({
							static: 'hidden w-16 max-w-lg',
							dynamic: {
								'lg:inline-block': navSidebarStore.minimal,
							},
						})}
						draggable="false"
					/>
				</NavLink>
			</div>

			<div className={'flex flex-col justify-between grow'}>
				<div className={'py-4 mt-4 font-light'}>
					<NavSidebarLink
						title={'Overview'}
						route={'/overview'}
						icon={{ icon: IconEnum.HOME, size: '30px' }}
						className={'my-2'}
					/>

					<NavSidebarLink
						title={'Experiences'}
						route={'/experiences'}
						icon={{ icon: IconEnum.PLACE, size: '30px' }}
						className={'my-2'}
					/>

					<NavSidebarLink
						title={'Tags'}
						route={'/tags'}
						icon={{ icon: IconEnum.TAG, size: '28px' }}
						className={'my-2'}
					/>

					<NavSidebarLink
						title={'Creators'}
						route={'/creators'}
						icon={{ icon: IconEnum.GROUP, size: '28px' }}
						className={'my-2'}
					/>

					<NavSidebarLink
						title={'Daily Picks'}
						route={'/daily-picks'}
						icon={{ icon: IconEnum.DAILY_PICKS, size: '28px' }}
						className={'my-2'}
					/>

					<ExpansionPanel
						title={'Compare'}
						icon={{ icon: IconEnum.COMPARE, size: '28px' }}
						className={ClassString({
							static: 'text-xl text-gray-100 my-2',
							dynamic: {
								'lg:hidden': navSidebarStore.minimal,
							},
						})}
						colorOnHover
						selected={inCompareView()}
						titleOptions={{
							titleHeight: '42px',
							titleBorderRadius: true,
						}}
						initialState={compareOpen() ? 'expanded' : 'closed'}
						onStateChange={(open) => {
							storeMenuOpen(NavBarMenuKey.COMPARE, open);
						}}
					>
						<div className={'px-6'}>
							<NavSidebarLink
								title={'Compare Experiences'}
								route={'/compare/experiences'}
							/>

							<NavSidebarLink
								title={'Compare Tags'}
								route={'/compare/tags'}
							/>

							<NavSidebarLink
								title={'Compare Tag & Experiences'}
								route={'/compare/experience-tag'}
								whiteSpaceWrap
							/>
						</div>
					</ExpansionPanel>

					<div
						className={ClassString({
							static: 'hidden p-2 my-2 hover:bg-blue-500 rounded-md cursor-pointer w-full items-center justify-center',
							dynamic: {
								'lg:flex': navSidebarStore.minimal,
								'bg-blue-500': inCompareView(),
							},
						})}
						onClick={() => {
							navSidebarStore.setMinimal(false);
							setMinimizeOnMouseLeave(true);
						}}
					>
						<Icon
							icon={IconEnum.COMPARE}
							size={'28px'}
							className={'text-white'}
						/>
					</div>

					<ExpansionPanel
						title={'Tools'}
						icon={{ icon: IconEnum.TOOLS, size: '28px' }}
						className={ClassString({
							static: 'text-xl text-gray-100 my-2 ',
							dynamic: { 'lg:hidden': navSidebarStore.minimal },
						})}
						colorOnHover
						selected={inToolsView()}
						titleOptions={{
							titleHeight: '42px',
							titleBorderRadius: true,
						}}
						initialState={toolsOpen() ? 'expanded' : 'closed'}
						onStateChange={(open) => {
							storeMenuOpen(NavBarMenuKey.TOOLS, open);
						}}
					>
						<div className={'px-6'}>
							<NavSidebarLink
								title={'Robux Converter'}
								route={'/robux'}
							/>
						</div>

						<div className={'px-6'}>
							<NavSidebarLink
								title={'Roblox Deep Link'}
								route={'/deep-link'}
							/>
						</div>

						{authStore.havePermission(
							UserPermission.EDIT_GAME_CREDENTIALS
						) && (
							<div className={'px-6'}>
								<NavSidebarLink
									title={'Event Credentials'}
									route={'/event-credentials'}
								/>
							</div>
						)}
					</ExpansionPanel>

					<div
						className={ClassString({
							static: 'hidden p-2 my-2 hover:bg-blue-500 rounded-md cursor-pointer w-full items-center justify-center',
							dynamic: {
								'lg:flex': navSidebarStore.minimal,
								'bg-blue-500': inToolsView(),
							},
						})}
						onClick={() => {
							navSidebarStore.setMinimal(false);
							setMinimizeOnMouseLeave(true);
						}}
					>
						<Icon
							icon={IconEnum.TOOLS}
							size={'28px'}
							className={'text-white'}
						/>
					</div>

					{authStore.havePermission(
						UserPermission.VIEW_CODE_LIBRARY
					) && (
						<NavSidebarLink
							title={'Code Library'}
							route={'/code-library'}
							icon={{ icon: IconEnum.DESCRIPTION, size: '28px' }}
							className={'my-2'}
						/>
					)}

					{authStore.havePermission(UserPermission.LIST_USERS) && (
						<NavSidebarLink
							title={'User Management'}
							route={'/users'}
							icon={{
								icon: IconEnum.MANAGE_ACCOUNTS,
								size: '28px',
							}}
							className={'my-2'}
						/>
					)}
				</div>

				<div
					className={
						'lg:hidden py-4 mt-4 font-light border-t border-gray-500'
					}
				>
					<div
						className={
							'flex items-center justify-between text-white text-lg px-4 py-2'
						}
					>
						<div className={'mr-2 flex items-center'}>
							<Icon
								icon={IconEnum.ACCOUNT}
								size={'20px'}
								className={'mr-2'}
							/>

							<div className={'mr-2'}>
								{`${authStore.user?.first_name ?? ''} ${
									authStore.user?.last_name ?? ''
								}`}
							</div>
						</div>

						<ThemeSelector
							className={ClassString({
								dynamic: {
									'text-gray-600': themeStore.lightTheme,
								},
							})}
						/>
					</div>

					<div
						className={'flex items-center text-white text-lg py-2'}
					>
						<div
							className={
								'nav-sidebar-link flex items-center mr-2 px-4  py-2 w-full cursor-pointer hover:bg-blue-500 rounded-md'
							}
							onClick={() => {
								void logoutHandler();
							}}
						>
							<Icon
								icon={IconEnum.LOGOUT}
								size={'20px'}
								className={'mr-2'}
							/>
							Log Out
						</div>
					</div>
				</div>
			</div>

			<div
				className={ClassString({
					static: 'w-full hidden lg:flex justify-between items-center mb-4 flex-wrap gap-y-2 border-t border-gray-500 pt-2',
					dynamic: {
						'justify-start': navSidebarStore.minimal,
					},
				})}
			>
				<GlobalExperienceSearch />

				<div
					className={ClassString({
						static: 'p-1 hover:bg-blue-500 rounded-md cursor-pointer flex justify-center items-center',
						dynamic: { 'w-40': navSidebarStore.minimal },
					})}
					onClick={() => {
						navSidebarStore.setMinimal(!navSidebarStore.minimal);
					}}
				>
					<Icon
						icon={
							navSidebarStore.minimal
								? IconEnum.ARROW_FORWARD
								: IconEnum.ARROW_BACK
						}
						size={'2.25rem'}
						className={'text-white'}
					/>
				</div>
			</div>
		</aside>
	);
});

export default NavSidebar;
