import { observer } from 'mobx-react-lite';
import DataComparisonRow from '@components/data/data-comparison-row/data-comparison-row.tsx';
import { Experience } from '@/schemas/experience-schema.ts';
import formatNumberWithSuffix from '@utils/format-number-with-suffix.ts';
import { useEffect, useState } from 'react';
import placeStatsStore from '@store/place-stats-store.ts';

interface ExperienceComparisonTwoExperiencesProps {
	experience1: Experience;
	experience2: Experience;
}

const formatCCU = (ccu: number, approx?: boolean): string => {
	return ccu ? `${approx ? '~' : ''}${ccu.toFixed(2)}` : '?';
};

const getCUUDirection = (ccu1: number, ccu2: number) => {
	if (!ccu1 && !ccu2) {
		return 'none';
	}

	if (ccu1 > ccu2) {
		return 'left';
	} else {
		return 'right';
	}
};

const ExperienceComparisonTwoExperiences = observer(
	function ExperienceComparisonTwoExperiences(
		props: ExperienceComparisonTwoExperiencesProps
	) {
		const [experience1DailyCCU, setExperience1DailyCCU] = useState(0);
		const [experience1WeeklyCCU, setExperience1WeeklyCCU] = useState(0);
		const [experience1MonthlyCCU, setExperience1MonthlyCCU] = useState(0);
		const [experience2DailyCCU, setExperience2DailyCCU] = useState(0);
		const [experience2WeeklyCCU, setExperience2WeeklyCCU] = useState(0);
		const [experience2MonthlyCCU, setExperience2MonthlyCCU] = useState(0);

		useEffect(() => {
			const getDailyCCU = async () => {
				if (props.experience1 && props.experience2) {
					const dailyCCU = await placeStatsStore.getDailyCCU([
						props.experience1.place_id,
						props.experience2.place_id,
					]);
					setExperience1DailyCCU(
						dailyCCU[props.experience1.place_id] ?? 0
					);
					setExperience2DailyCCU(
						dailyCCU[props.experience2.place_id] ?? 0
					);
				} else {
					setExperience1DailyCCU(0);
					setExperience2DailyCCU(0);
				}
			};

			void getDailyCCU();
		}, [props.experience1, props.experience2]);

		useEffect(() => {
			const getMonthlyCCU = async () => {
				if (props.experience1 && props.experience2) {
					const weeklyCCU = await placeStatsStore.getWeeklyCCU([
						props.experience1.place_id,
						props.experience2.place_id,
					]);
					setExperience1WeeklyCCU(
						weeklyCCU[props.experience1.place_id] ?? 0
					);
					setExperience2WeeklyCCU(
						weeklyCCU[props.experience2.place_id] ?? 0
					);
				} else {
					setExperience1WeeklyCCU(0);
					setExperience2WeeklyCCU(0);
				}
			};

			void getMonthlyCCU();
		}, [props.experience1, props.experience2]);

		useEffect(() => {
			const getMonthlyCCU = async () => {
				if (props.experience1 && props.experience2) {
					const monthlyCCU = await placeStatsStore.getMonthlyCCU([
						props.experience1.place_id,
						props.experience2.place_id,
					]);
					setExperience1MonthlyCCU(
						monthlyCCU[props.experience1.place_id] ?? 0
					);
					setExperience2MonthlyCCU(
						monthlyCCU[props.experience2.place_id] ?? 0
					);
				} else {
					setExperience1MonthlyCCU(0);
					setExperience2MonthlyCCU(0);
				}
			};

			void getMonthlyCCU();
		}, [props.experience1, props.experience2]);

		return (
			<div className={'flex flex-col justify-center'}>
				<DataComparisonRow
					label={'Name'}
					dataLeft={props.experience1.canonical_name}
					dataRight={props.experience2.canonical_name}
					borderBottom
				/>

				<DataComparisonRow
					label={'Player Count'}
					dataLeft={`${props.experience1.players_online}`}
					dataRight={`${props.experience2.players_online}`}
					dataDifference={{
						direction:
							props.experience1.players_online >
							props.experience2.players_online
								? 'left'
								: 'right',
						data:
							props.experience1.players_online >
							props.experience2.players_online
								? `${props.experience1.players_online - props.experience2.players_online}`
								: `${props.experience2.players_online - props.experience1.players_online}`,
					}}
					borderBottom
				/>

				<DataComparisonRow
					label={'Like %'}
					dataLeft={`${(props.experience1.like_percentage * 100).toFixed(2)}%`}
					dataRight={`${(props.experience2.like_percentage * 100).toFixed(2)}%`}
					dataDifference={{
						direction:
							props.experience1.like_percentage >
							props.experience2.like_percentage
								? 'left'
								: 'right',
						data:
							props.experience1.like_percentage >
							props.experience2.like_percentage
								? `${((props.experience1.like_percentage - props.experience2.like_percentage) * 100).toFixed(2)}%`
								: `${((props.experience2.like_percentage - props.experience1.like_percentage) * 100).toFixed(2)}%`,
					}}
					borderBottom
				/>

				<DataComparisonRow
					label={'Visits'}
					dataLeft={formatNumberWithSuffix(
						props.experience1.total_visits
					)}
					dataRight={formatNumberWithSuffix(
						props.experience2.total_visits
					)}
					dataDifference={{
						direction:
							props.experience1.total_visits >
							props.experience2.total_visits
								? 'left'
								: 'right',
						data:
							props.experience1.total_visits >
							props.experience2.total_visits
								? formatNumberWithSuffix(
										props.experience1.total_visits -
											props.experience2.total_visits
									)
								: formatNumberWithSuffix(
										props.experience2.total_visits -
											props.experience1.total_visits
									),
					}}
					borderBottom
				/>

				<div title={'Last completed day.'}>
					<DataComparisonRow
						label={'Daily CCU'}
						dataLeft={formatCCU(experience1DailyCCU, true)}
						dataRight={formatCCU(experience2DailyCCU, true)}
						dataDifference={{
							direction: getCUUDirection(
								experience1DailyCCU,
								experience2DailyCCU
							),
							data:
								experience1DailyCCU > experience2DailyCCU
									? formatCCU(
											experience1DailyCCU -
												experience2DailyCCU
										)
									: formatCCU(
											experience2DailyCCU -
												experience1DailyCCU
										),
						}}
						borderBottom
					/>
				</div>

				<div title={'Last completed week.'}>
					<DataComparisonRow
						label={'Weekly CCU'}
						dataLeft={formatCCU(experience1WeeklyCCU, true)}
						dataRight={formatCCU(experience2WeeklyCCU, true)}
						dataDifference={{
							direction: getCUUDirection(
								experience1WeeklyCCU,
								experience2WeeklyCCU
							),
							data:
								experience1WeeklyCCU > experience2WeeklyCCU
									? formatCCU(
											experience1WeeklyCCU -
												experience2WeeklyCCU
										)
									: formatCCU(
											experience2WeeklyCCU -
												experience1WeeklyCCU
										),
						}}
						borderBottom
					/>
				</div>

				<div title={'Last completed month.'}>
					<DataComparisonRow
						label={'Monthly CCU'}
						dataLeft={formatCCU(experience1MonthlyCCU, true)}
						dataRight={formatCCU(experience2MonthlyCCU, true)}
						dataDifference={{
							direction: getCUUDirection(
								experience1MonthlyCCU,
								experience2MonthlyCCU
							),
							data:
								experience1MonthlyCCU > experience2MonthlyCCU
									? formatCCU(
											experience1MonthlyCCU -
												experience2MonthlyCCU
										)
									: formatCCU(
											experience2MonthlyCCU -
												experience1MonthlyCCU
										),
						}}
					/>
				</div>
			</div>
		);
	}
);

export default ExperienceComparisonTwoExperiences;
