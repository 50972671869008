import { observer } from 'mobx-react-lite';
import Button from '@components/core/button/button.tsx';
import { PlaceStatGranularity } from '@store/place-stats-store.ts';
import useScreenBreakpoint from '@hooks/use-screen-breakpoint.tsx';
import { ScreenBreakpoint } from '@/enums/screen-breakpoints.ts';

interface GranularitySelectorPros {
	updateGranularity: (granularity: PlaceStatGranularity) => unknown;
	selectedGranularity: PlaceStatGranularity;
	availableGranularities?: PlaceStatGranularity[];
}

const GranularitySelector = observer(function GranularitySelector(
	props: GranularitySelectorPros
) {
	const screenBreakpoint = useScreenBreakpoint();

	const availableGranularities = props.availableGranularities?.length
		? props.availableGranularities
		: Object.values(PlaceStatGranularity);

	return (
		<div className={'flex items-center gap-4'}>
			{availableGranularities.includes(PlaceStatGranularity.HOUR) && (
				<Button
					title={
						screenBreakpoint <= ScreenBreakpoint.SM ? 'H' : 'Hourly'
					}
					onClick={() => {
						props.updateGranularity(PlaceStatGranularity.HOUR);
					}}
					selected={
						props.selectedGranularity === PlaceStatGranularity.HOUR
					}
				/>
			)}

			{availableGranularities.includes(PlaceStatGranularity.DAY) && (
				<Button
					title={
						screenBreakpoint <= ScreenBreakpoint.SM ? 'D' : 'Daily'
					}
					onClick={() => {
						props.updateGranularity(PlaceStatGranularity.DAY);
					}}
					selected={
						props.selectedGranularity === PlaceStatGranularity.DAY
					}
				/>
			)}

			{availableGranularities.includes(PlaceStatGranularity.WEEK) && (
				<Button
					title={
						screenBreakpoint <= ScreenBreakpoint.SM ? 'W' : 'Weekly'
					}
					onClick={() => {
						props.updateGranularity(PlaceStatGranularity.WEEK);
					}}
					selected={
						props.selectedGranularity === PlaceStatGranularity.WEEK
					}
				/>
			)}

			{availableGranularities.includes(PlaceStatGranularity.MONTH) && (
				<Button
					title={
						screenBreakpoint <= ScreenBreakpoint.SM
							? 'M'
							: 'Monthly'
					}
					onClick={() => {
						props.updateGranularity(PlaceStatGranularity.MONTH);
					}}
					selected={
						props.selectedGranularity === PlaceStatGranularity.MONTH
					}
				/>
			)}
		</div>
	);
});

export default GranularitySelector;
