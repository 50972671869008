import { z } from 'zod';

export interface DailyPicksExperience {
	name: string;
	place_id: number;
}

export interface DailyPicksResponse {
	places: DailyPicksExperience[];
	date: string; //Date string
}

export interface DailyPicks {
	places: DailyPicksExperience[];
	date: Date;
}

export const DailyPicksExperienceResponseSchema: z.ZodType<DailyPicksExperience> =
	z.object({ name: z.string(), place_id: z.number() });

export const DailyPicksResponseSchema: z.ZodType<DailyPicksResponse> = z.object(
	{
		places: z.array(DailyPicksExperienceResponseSchema),
		date: z.string(),
	}
);

export const DailyPicksResponseArraySchema: z.ZodType<DailyPicksResponse[]> =
	z.array(DailyPicksResponseSchema);
