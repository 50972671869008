import { observer } from 'mobx-react-lite';
import { IconEnum } from '@components/core/icon/icon-enum.ts';
import { useRef } from 'react';
import Button from '@components/core/button/button.tsx';
import optionsMenuStore from '@store/options-menu-store.ts';

interface OptionsMenuProps {
	buttons: {
		title: string;
		onClick: () => unknown;
	}[];
	width?: string;
}

const OptionsMenu = observer(function OptionsMenu(props: OptionsMenuProps) {
	const menuButton = useRef<HTMLDivElement>(null);

	const onClick = () => {
		if (menuButton) {
			optionsMenuStore.open({ ...props, ref: menuButton });
		}
	};

	return (
		<div className={'cursor-pointer relative'} ref={menuButton}>
			<Button
				title={''}
				icon={{ icon: IconEnum.MORE, placement: 'left', size: '2rem' }}
				onClick={onClick}
				round
			/>
		</div>
	);
});

export default OptionsMenu;
