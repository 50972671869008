import { ReactElement } from 'react';
import { makeAutoObservable } from 'mobx';

interface ModalOptions {
	overflow: boolean;
	clickOutSideToClose: boolean;
	className: string;
}

type OptionalModalOptions = Partial<ModalOptions>;

class ModalStore {
	private defaultOptions: ModalOptions = {
		overflow: false,
		clickOutSideToClose: true,
		className: '',
	};

	private activeModal: ReactElement | undefined;
	private activeOptions: ModalOptions = { ...this.defaultOptions };

	constructor() {
		makeAutoObservable(this);
	}

	private resetOptions() {
		this.activeOptions = { ...this.defaultOptions };
	}

	open(content: ReactElement, options?: OptionalModalOptions) {
		this.activeModal = content;
		this.activeOptions = { ...this.activeOptions, ...options };
	}

	close() {
		this.activeModal = undefined;
		this.resetOptions();
	}

	get content() {
		return this.activeModal;
	}

	get visible() {
		return !!this.activeModal;
	}

	get options() {
		return this.activeOptions;
	}
}

const modalStore = new ModalStore();
export default modalStore;
