import placeTagStore from '@store/place-tag-store.ts';

export interface ExperienceTagDetailsLoaderReturn {
	experienceTagId: string;
}

export async function ExperienceTagDetailsLoader(
	experienceTagId: string
): Promise<ExperienceTagDetailsLoaderReturn | null> {
	if (placeTagStore.populatedPlaceTag(+experienceTagId)) {
		void placeTagStore.getPopulatedPlaceTag(+experienceTagId);
		return { experienceTagId: experienceTagId };
	} else {
		await placeTagStore.getPopulatedPlaceTag(+experienceTagId);
	}

	if (!placeTagStore.populatedPlaceTag(+experienceTagId)) {
		throw new Response('Not Found', { status: 404 });
	}

	return { experienceTagId };
}
