import { observer } from 'mobx-react-lite';
import TextArea from '@components/core/text-area/text-area.tsx';
import { ChangeEvent, useState } from 'react';
import RobloxUrlToPlaceId from '@utils/roblox-url-to-place-id.ts';
import validate from '@services/validation-service.ts';
import Button from '@components/core/button/button.tsx';
import { BUTTON_STYLE } from '@components/core/button/button-style-enum.ts';
import modalStore from '@store/modal-store.ts';

interface ExperienceTagDetailsBulkAddProps {
	tagName: string;
	submit: (placeIds: string[]) => unknown;
}

const ExperienceTagDetailsBulkAdd = observer(
	function ExperienceTagDetailsBulkAdd(
		props: ExperienceTagDetailsBulkAddProps
	) {
		const [bulkExperienceIdsString, setBulkExperienceIdsString] =
			useState('');
		const [bulkExperienceIds, setBulkExperienceIds] = useState<string[]>(
			[]
		);
		const [validBulkExperienceIds, setValidBulkExperienceIds] =
			useState(false);
		const [showError, setShowError] = useState(false);

		const handleBulkExperienceIdsChange = (
			event: ChangeEvent<HTMLTextAreaElement>
		) => {
			let newString = event.target.value;
			if (newString.length > bulkExperienceIdsString.length) {
				const slicedString = newString.slice(
					bulkExperienceIdsString.length
				);
				const newPlaceId = RobloxUrlToPlaceId(slicedString);
				if (slicedString !== newPlaceId) {
					newString = `${bulkExperienceIdsString}${newPlaceId}`;
				}
			}

			setBulkExperienceIdsString(newString);

			let valid = false;

			if (/[\s,]+/.test(newString)) {
				const splitString = newString.split(/[\s,]+/);
				const filteredString = splitString.filter(
					(string) => !/[\s,]+/.test(string) && !!string
				);
				valid = filteredString.every((placeId) =>
					validate.databaseId(placeId)
				);
				if (valid) {
					setBulkExperienceIds(filteredString);
				}
			} else {
				valid = validate.databaseId(newString);

				if (valid) {
					setBulkExperienceIds([newString]);
				}
			}

			setValidBulkExperienceIds(valid);

			setShowError(!valid && !!newString.length);
		};

		return (
			<div className={'flex flex-col items-center'}>
				<div className={'mb-2'}>
					Bulk add experiences to the tag {`"${props.tagName}"`}. The
					place IDs needs to be separated by either spaces, new lines
					or commas.
				</div>

				<div
					className={
						'mb-6 mt-4 w-full relative flex justify-center min-h-48'
					}
				>
					<TextArea
						label={'Experience IDs'}
						value={bulkExperienceIdsString}
						onChange={handleBulkExperienceIdsChange}
						className={'w-full min-h-48'}
						minHeight={'100%'}
					/>

					{showError && (
						<div
							className={'absolute bottom-3 right-4 text-red-500'}
						>
							format is not valid
						</div>
					)}
				</div>

				<div className={'w-full flex justify-evenly mt-2'}>
					<Button
						title={'Cancel'}
						onClick={() => {
							modalStore.close();
						}}
					/>

					<Button
						title={'Add Experiences'}
						onClick={() => {
							props.submit(bulkExperienceIds);
							modalStore.close();
						}}
						buttonStyle={BUTTON_STYLE.PRIMARY}
						disabled={!validBulkExperienceIds}
					/>
				</div>
			</div>
		);
	}
);

export default ExperienceTagDetailsBulkAdd;
