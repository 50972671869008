import { Creator } from '@/schemas/creator-schema.ts';
import creatorStore from '@store/creator-store.ts';
import viewStore from '@store/view-store.ts';

export interface CreatorDetailsLoaderReturn {
	creator: Creator;
}

export async function CreatorDetailsLoader(creatorId: string) {
	viewStore.loadingViewData = true;

	await creatorStore.waitForInit();

	const creator = creatorStore.getCreatorById(+creatorId);
	if (!creator) {
		throw new Response(null, {
			status: 404,
		});
	}

	return { creator };
}
