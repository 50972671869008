import { observer } from 'mobx-react-lite';
import Button from '@components/core/button/button.tsx';
import { useLoaderData, useNavigate } from 'react-router-dom';
import View from '@components/view/view.tsx';
import placeTagStore from '@store/place-tag-store.ts';
import { IconEnum } from '@components/core/icon/icon-enum.ts';
import { ExperienceTagDetailsLoaderReturn } from '@views/experience-tag-details/experience-tag-details-loader.ts';
import PlaceStats from '@components/data/place-stats/place-stats.tsx';
import {
	PlaceStatGranularity,
	PlaceStatType,
} from '@store/place-stats-store.ts';
import PlaceGenreDistribution from '@components/data/place-genre-distribution/place-genre-distribution.tsx';
import QuickData from '@components/data/quick-data/quick-data.tsx';
import GroupBy from '@utils/group-by.ts';
import { FullExperience } from '@/schemas/experience-schema.ts';
import ViewPanel from '@components/view/view-panel.tsx';
import ViewPanelSizeEnum from '@components/view/view-panel-size-enum.ts';
import LightdashDashboards from '@components/data/lightdash-dashboards/lightdash-dashboards.tsx';
import DashboardType from '@components/data/lightdash-dashboards/dashboard-type.ts';
import PlaceTagDetailsAdd from '@views/experience-tag-details/view-components/experience-tag-details-add.tsx';
import ExperienceTable from '@components/tables/experience-table.tsx';
import { CustomCellRendererProps } from 'ag-grid-react';
import OptionsMenu from '@components/core/options-menu/options-menu.tsx';
import copyService from '@services/copy-service.ts';
import ModalBase from '@components/modal/modal-base.tsx';
import { PopulatedPlaceTag } from '@/schemas/plage-tag-schema.ts';
import toastStore from '@store/toast-store.ts';
import { ToastType } from '@components/service/toast/toast-enums.ts';
import { useEffect } from 'react';
import postHogService from '@services/posthog-service.ts';
import { PostHogEventType } from '@/enums/posthog-event.ts';
import creatorStore from '@store/creator-store.ts';
import ViewExpansionPanel from '@components/view/view-expansion-panel.tsx';
import modalStore from '@store/modal-store.ts';
import ExpansionButton from '@components/core/expansion-button/expansion-button.tsx';
import Icon from '@components/core/icon/icon.tsx';
import ExperienceTagDetailsCcu from '@views/experience-tag-details/view-components/experience-tag-details-ccu.tsx';
import authStore from '@store/auth-store.ts';
import { UserPermission } from '@/schemas/user-schema.ts';
import ClassString from '@utils/class-string.ts';
import ExperienceTagDetailsBulkAdd from '@views/experience-tag-details/view-components/experience-tag-details-bulk-add.tsx';
import Tooltip from '@components/core/tooltip/tooltip.tsx';

const robloxPlaceUrl = 'https://www.roblox.com/games/';

const removeExperience = async (
	populatedExperienceTag: PopulatedPlaceTag,
	experience: FullExperience
) => {
	await placeTagStore.deletePlaceIdFromTag(
		populatedExperienceTag.id,
		experience.place_id
	);

	toastStore.emit(
		`Successfully removed experience from tag`,
		ToastType.CONFIRM
	);
};

const ExperienceTagDetails = observer(function ExperienceTagDetails() {
	const { experienceTagId } =
		useLoaderData() as ExperienceTagDetailsLoaderReturn;
	const populatedExperienceTag =
		placeTagStore.populatedPlaceTag(+experienceTagId);

	const canAddExperiences =
		authStore.havePermission(UserPermission.EDIT_TAG) ??
		populatedExperienceTag.owner_id === authStore.user?.id;

	const navigate = useNavigate();

	const top5Experiences = [...populatedExperienceTag.places]
		.sort((a, b) => b.players_online - a.players_online)
		.slice(0, 5);

	const addExperienceToTag = (id: number) => {
		const experienceList = populatedExperienceTag?.places.map(
			(place) => place.place_id
		);
		experienceList?.push(id);
		void placeTagStore.updatePlaceTag(populatedExperienceTag.id, {
			...populatedExperienceTag,
			place_ids: experienceList,
		});
	};

	const bulkAddExperiencesToTag = (experienceIds: string[]) => {
		const experienceList = populatedExperienceTag?.places.map(
			(place) => place.place_id
		);
		experienceList?.push(...experienceIds.map((placeId) => +placeId));
		void placeTagStore.updatePlaceTag(populatedExperienceTag.id, {
			...populatedExperienceTag,
			place_ids: experienceList,
		});
	};

	const placesGroupedByCreator = GroupBy(
		populatedExperienceTag.places,
		(place: FullExperience) => place.creator_id
	);

	const topCreator = Object.entries(
		Object.fromEntries(placesGroupedByCreator.entries())
	)
		.map((entry) => {
			return {
				name:
					creatorStore.getCreatorById(entry[1][0].creator_id)?.name ??
					'Unknown Creator',
				places: entry[1],
				totalPlayers: entry[1].reduce(
					(a, b) => a + b.players_online || 0,
					0
				),
			};
		})
		.sort((a, b) => b.totalPlayers - a.totalPlayers)[0];

	useEffect(() => {
		postHogService.capture({
			type: PostHogEventType.ViewTag,
			tag: populatedExperienceTag.name,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<View
			viewInfo={{
				title: populatedExperienceTag?.name ?? '',
				icon: IconEnum.TAG,
				breadCrumbs: [{ title: 'Tags', route: '/tags' }],
			}}
			headerChildren={
				populatedExperienceTag && (
					<div
						className={
							'w-full flex items-center justify-between flex-wrap'
						}
					>
						<div
							className={
								'flex justify-center items-center gap-2 text-sm'
							}
						>
							{populatedExperienceTag.global && (
								<Tooltip
									text={'Curated'}
									direction={'right'}
									widthClass={'w-fit'}
								>
									<Icon
										icon={IconEnum.CURATED}
										size={'2rem'}
									/>
								</Tooltip>
							)}
						</div>

						<div
							className={
								'flex items-center justify-end flex-wrap'
							}
						>
							{canAddExperiences && (
								<Button
									title={'Add'}
									icon={{
										icon: IconEnum.ADD,
										placement: 'right',
									}}
									className={'mx-4'}
									onClick={() =>
										modalStore.open(
											<PlaceTagDetailsAdd
												tagName={
													populatedExperienceTag?.name ??
													''
												}
												submit={addExperienceToTag}
											/>,
											{ overflow: true }
										)
									}
								/>
							)}

							<div
								className={ClassString({
									dynamic: {
										'border-l pl-4 dark:border-gray-400':
											canAddExperiences,
									},
								})}
							>
								<ExpansionButton
									title={'Tag Options'}
									subMenuButtons={[
										{
											title: (
												<div
													className={
														'flex justify-center items-center gap-2 px-4'
													}
												>
													{'Compare To Experience'}
													<Icon
														icon={IconEnum.COMPARE}
														size={'1.5rem'}
													/>
												</div>
											),
											onClick: () => {
												navigate(
													`/compare/experience-tag#tag-1=${populatedExperienceTag.id}`
												);
											},
										},
										{
											title: (
												<div
													className={
														'flex justify-center items-center gap-2 px-4'
													}
												>
													{'Compare To Tag'}
													<Icon
														icon={IconEnum.COMPARE}
														size={'1.5rem'}
													/>
												</div>
											),
											onClick: () => {
												navigate(
													`/compare/tags#tag-1=${populatedExperienceTag.id}`
												);
											},
										},
										...(canAddExperiences
											? [
													{
														title: (
															<div
																className={
																	'flex justify-center items-center gap-2 px-4'
																}
															>
																{'Bulk Add'}
																<Icon
																	icon={
																		IconEnum.BULK_ADD
																	}
																/>
															</div>
														),
														onClick: () => {
															modalStore.open(
																<ExperienceTagDetailsBulkAdd
																	tagName={
																		populatedExperienceTag?.name ??
																		''
																	}
																	submit={
																		bulkAddExperiencesToTag
																	}
																/>
															);
														},
													},
												]
											: []),
									]}
								/>
							</div>
						</div>
					</div>
				)
			}
			className={'experience-tag-details'}
		>
			<ViewExpansionPanel
				title={`Experiences (${populatedExperienceTag.places.length})`}
				startExpanded
			>
				<ExperienceTable
					experiences={populatedExperienceTag.places}
					contextMenu={{
						cellRenderer: (params: CustomCellRendererProps) => {
							const place = params.node.data as FullExperience;
							return (
								<OptionsMenu
									buttons={[
										{
											title: 'View on roblox.com',
											onClick: () => {
												window.open(
													`${robloxPlaceUrl}${place.place_id}`
												);
											},
										},
										{
											title: 'Remove',
											onClick: () => {
												modalStore.open(
													<ModalBase
														title={`Remove experience from tag`}
														content={{
															description: `Are you sure you want to remove "${place.name}" from the tag "${populatedExperienceTag.name}"?`,
														}}
														onConfirm={async () => {
															await removeExperience(
																populatedExperienceTag,
																place
															);
														}}
														options={{
															confirmButtonTitle:
																'Remove',
														}}
													/>
												);
											},
										},
										{
											title: 'Copy Experience ID',
											onClick: () => {
												copyService.experienceId(place);
											},
										},
										{
											title: 'Copy Universe ID',
											onClick: () => {
												copyService.experienceUniverseId(
													place
												);
											},
										},
									]}
									width={'13rem'}
								/>
							);
						},
						minWidth: 70,
						maxWidth: 70,
						resizable: false,
						sortable: false,
						cellStyle: {
							display: 'flex',
							alignItems: 'center',
						},
						context: {
							priority: 1,
						},
					}}
					fillParent
					includeRanking={false}
				/>
			</ViewExpansionPanel>

			<LightdashDashboards
				dashboards={[
					{
						type: DashboardType.EXPERIENCE_INSIGHT_BY_TAG,
						tagName: populatedExperienceTag?.name,
					},
				]}
			/>

			{!!populatedExperienceTag.places.length && (
				<>
					<ViewPanel options={{ size: ViewPanelSizeEnum.HALF }}>
						<PlaceGenreDistribution
							places={[...populatedExperienceTag.places]}
						/>
					</ViewPanel>

					<ViewPanel
						options={{
							size: ViewPanelSizeEnum.HALF,
							centerContent: true,
						}}
					>
						<QuickData
							data={new Intl.NumberFormat(
								navigator.language
							).format(
								populatedExperienceTag.places.reduce(
									(a, b) => a + b.players_online || 0,
									0
								)
							)}
							title={'Total Players Online'}
						/>

						<QuickData
							data={new Intl.NumberFormat(
								navigator.language
							).format(
								populatedExperienceTag.places.reduce(
									(a, b) => a + b.total_visits || 0,
									0
								)
							)}
							title={'Total Visits'}
						/>

						<QuickData
							data={
								new Set(
									populatedExperienceTag.places.map(
										(place) => place.creator_id
									)
								).size
							}
							title={'Creators'}
						/>

						<QuickData
							data={topCreator?.name}
							title={'Top Creator (Players Online)'}
						/>
					</ViewPanel>

					<ExperienceTagDetailsCcu tag={populatedExperienceTag} />
				</>
			)}

			{!!top5Experiences.length && (
				<ViewPanel>
					<PlaceStats
						places={top5Experiences}
						statType={PlaceStatType.CCUS}
						title={'Concurrent Users (Current Top 5)'}
						options={{
							showLegend: true,
							useLineGraph: true,
							thickerLines: true,
							selectedRange: 7,
							defaultGranularity: PlaceStatGranularity.WEEK,
						}}
					/>
				</ViewPanel>
			)}
		</View>
	);
});

export default ExperienceTagDetails;
