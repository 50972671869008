import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import authStore from '@store/auth-store.ts';
import LoadingIndicator from '@components/core/loading-indicator/loading-indicator.tsx';
import viewStore, { ViewInfo } from '@store/view-store.ts';
import ClassString from '@utils/class-string.ts';
import ScrollProvider from '@components/providers/scroll-provider.tsx';
import { useNavigation } from 'react-router-dom';

interface ViewProps {
	children: React.ReactNode;
	viewInfo?: ViewInfo;
	hideHeader?: boolean;
	headerChildren?: React.ReactNode;
	className?: string;
	fullHeight?: boolean;
	loading?: boolean;
	center?: boolean;
}

const View = observer(function View(props: ViewProps) {
	const { state } = useNavigation();

	useEffect(() => {
		if (props.viewInfo) {
			document.title = `${props.viewInfo.title} | Tools`;
			viewStore.setViewInfo(props.viewInfo);
		}
	}, [props.viewInfo]);

	return (
		<ScrollProvider>
			<div
				className={ClassString({
					static: 'mx-auto max-w-screen-2xl px-2 lg:px-6',
					dynamic: {
						'h-full': props.fullHeight ?? props.loading,
						'h-screen -mt-nav': !authStore.isLoggedIn,
					},
					custom: props.className,
				})}
			>
				<div
					className={ClassString({
						static: 'flex flex-wrap justify-center gap-6 w-full',
						dynamic: {
							'h-full flex-col': props.fullHeight,
							'h-full items-center': !!props.loading,
							'flex-row items-center': state === 'loading',
						},
					})}
				>
					{!props.loading && !props.hideHeader && (
						<div
							className={
								'flex justify-between flex-wrap items-center w-full -my-2'
							}
						>
							{props.headerChildren}
						</div>
					)}

					{!props.loading && <>{props.children}</>}

					{props.loading && (
						<div className={'flex justify-center my-auto'}>
							<LoadingIndicator />
						</div>
					)}
				</div>
			</div>
		</ScrollProvider>
	);
});

export default View;
