import { observer } from 'mobx-react-lite';
import NavigationButton from '@components/navigation/navigation-button/navigation-button.tsx';
import { NavigationButtonEnum } from '@components/navigation/navigation-button/navigation-button-enum.ts';
import { PlaceTag } from '@/schemas/plage-tag-schema.ts';
import ClassString from '@utils/class-string.ts';
import { FullExperience } from '@/schemas/experience-schema.ts';
import authStore from '@store/auth-store.ts';
import { UserPermission } from '@/schemas/user-schema.ts';
import ExperienceDetailsTagAdder from '@views/experience-details/view-components/experience-details-tag-adder.tsx';

interface ExperienceDetailsTagsProps {
	experience: FullExperience;
	experienceTags: PlaceTag[];
	className?: string;
}

const ExperienceDetailsTags = observer(function ExperienceDetailsTags(
	props: ExperienceDetailsTagsProps
) {
	return (
		<div
			className={ClassString({
				static: 'w-full h-full',
				dynamic: {},
				custom: props.className,
			})}
		>
			<div
				className={
					'flex justify-between items-center gap-8 h-full flex-col md:flex-row'
				}
			>
				<div className={'flex flex-col justify-between w-full h-full'}>
					<div className={'text-2xl mb-2'}>{'Curated Tags'}</div>

					<div
						className={
							'flex flex-col gap-8 lg:gap-0 lg:flex-row w-full'
						}
					>
						<div
							className={ClassString({
								static: 'w-full flex flex-wrap justify-center items-center content-start gap-4 p-4 rounded-lg',
								dynamic: {
									'justify-center':
										!props.experienceTags.length,
								},
							})}
						>
							{!props.experienceTags.length && (
								<div
									className={
										'w-full h-full flex items-center justify-center'
									}
								>
									No tags
								</div>
							)}

							{props.experienceTags.map((tag) => {
								return (
									<NavigationButton
										path={`/tags/${tag.id}`}
										title={tag.name}
										direction={NavigationButtonEnum.NONE}
										key={tag.id}
										outline
										background
									/>
								);
							})}
						</div>
					</div>

					<div></div>
				</div>

				{authStore.havePermission(UserPermission.EDIT_TAG) && (
					<ExperienceDetailsTagAdder
						className={
							'pl-10 border-l-0 md:border-l border-gray-600 dark:border-gray-500'
						}
						experienceTags={props.experienceTags}
						experience={props.experience}
					/>
				)}
			</div>
		</div>
	);
});

export default ExperienceDetailsTags;
