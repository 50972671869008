import { observer } from 'mobx-react-lite';
import { ExperienceRanking } from '@/schemas/experience-rating.ts';
import experienceRankingStore from '@store/experience-ranking-store.ts';
import RankingCategories from '@views/experience-details/view-components/ranking-categories.ts';
import TGSTable, { CustomColDef } from '@components/core/table/table.tsx';
import { ValueFormatterParams } from 'ag-grid-community';
import { CustomCellRendererProps } from 'ag-grid-react';

const ColumnDefs: CustomColDef<ExperienceRanking>[] = [
	{
		headerName: 'Category',
		field: 'y',
		valueFormatter: (
			params: ValueFormatterParams<ExperienceRanking, number>
		) => {
			const y = params.value!;
			return RankingCategories[y].title ?? 'Unknown Category';
		},
		minWidth: 250,
		resizable: false,
		sortable: false,
		context: {
			priority: 1,
		},
		flex: 1,
	},
	{
		headerName: 'Rank',
		field: 'name',
		minWidth: 75,
		resizable: false,
		sortable: false,
		context: {
			priority: 1,
		},
	},
	{
		headerName: 'When',
		field: 'start',
		valueFormatter: (
			params: ValueFormatterParams<ExperienceRanking, Date>
		) => {
			const start = params.value;
			return start ? start.toLocaleDateString() : '-';
		},
		minWidth: 150,
		resizable: false,
		sortable: false,
		context: {
			priority: 2,
		},
	},
	{
		headerName: 'How Long',
		cellRenderer: (params: CustomCellRendererProps) => {
			const rank = params.node.data as ExperienceRanking;
			const differenceInMilliseconds = Math.abs(
				rank.end.getTime() - rank.start.getTime()
			);

			// Convert milliseconds to hours and minutes
			const millisecondsInAnHour = 1000 * 60 * 60;
			const millisecondsInAMinute = 1000 * 60;

			const totalHours = Math.floor(
				differenceInMilliseconds / millisecondsInAnHour
			);
			const remainingMilliseconds =
				differenceInMilliseconds % millisecondsInAnHour;
			const totalMinutes = Math.floor(
				remainingMilliseconds / millisecondsInAMinute
			);

			return `${Math.round(totalHours)}h ${totalMinutes}min`;
		},
		minWidth: 150,
		resizable: false,
		sortable: false,
		context: {
			priority: 3,
		},
	},
];

const getTopRankings = (rankings: ExperienceRanking[]): ExperienceRanking[] => {
	const rankToNumber = (s: string) => {
		return +s.replaceAll('#', '');
	};
	const topRankings: Record<number, ExperienceRanking> = {};

	for (const rank of rankings) {
		const currentTop = topRankings[rank.y];
		if (
			currentTop &&
			rankToNumber(currentTop.name) < rankToNumber(rank.name)
		) {
			continue;
		}

		if (
			currentTop &&
			currentTop.name === rank.name &&
			rankToNumber(currentTop.name) > rankToNumber(rank.name)
		) {
			continue;
		}

		topRankings[rank.y] = rank;
	}

	return Object.values(topRankings).map((rank) => {
		return { ...rank };
	});
};

interface ExperienceDetailsRankingTableProps {
	placeId: number;
	isHidden: boolean;
}

const ExperienceDetailsRankingTable = observer(
	function ExperienceDetailsRankingTable(
		props: ExperienceDetailsRankingTableProps
	) {
		const experienceRankings = experienceRankingStore.experienceRating(
			props.placeId
		);

		return (
			<>
				{!!experienceRankings?.length && (
					<>
						<div
							className={
								'w-full flex items-center justify-center text-2xl mt-2 mb-4'
							}
						>
							Ranking Milestones
						</div>

						<TGSTable
							columnDefs={ColumnDefs}
							rowData={getTopRankings(experienceRankings)}
							tableName={'experience-latest-top-rankings'}
							isHidden={props.isHidden}
							disablePagination
						/>
					</>
				)}
			</>
		);
	}
);

export default ExperienceDetailsRankingTable;
