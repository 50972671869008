import { observer } from 'mobx-react-lite';
import View from '@components/view/view.tsx';
import ViewPanel from '@components/view/view-panel.tsx';
import eventCredentialStore from '@store/event-credential-store.ts';
import EventCredentialInfo from '@views/event-credentials/view-components/event-credential-info.tsx';
import { IconEnum } from '@components/core/icon/icon-enum.ts';
import { useEffect, useState } from 'react';
import experienceStore from '@store/experience-store.ts';
import EventCredentialCreate from '@views/event-credentials/view-components/event-credential-create.tsx';
import SearchBar from '@components/core/search-bar/search-bar.tsx';
import searchService from '@services/search-service.ts';
import thumbnailStore from '@store/thumbnail-store.ts';
import { ThumbnailType } from '@/schemas/thumbnail-schema.ts';

const EventCredentials = observer(function EventCredentials() {
	const [searchTerm, setSearchTerm] = useState('');
	const [visibleSecret, setVisibleSecret] = useState('');

	const credentials = searchTerm.length
		? eventCredentialStore.eventCredentials.filter((cred) =>
				searchService.eventCredential(cred, searchTerm)
			)
		: eventCredentialStore.eventCredentials;

	useEffect(() => {
		void thumbnailStore.loadThumbnailsById(
			credentials.map((c) => c.place_id),
			ThumbnailType.ICON
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<View
			viewInfo={{
				title: 'Event Credentials',
				icon: IconEnum.TOOLS,
				iconSize: '28px',
				breadCrumbs: [{ title: 'Tools' }],
			}}
			className={'max-w-screen-lg'}
			hideHeader
			loading={!experienceStore.slimExperiences.length}
		>
			<ViewPanel className={'max-w-3xl'}>
				<EventCredentialCreate />
			</ViewPanel>

			<div className="border-t border-gray-500 w-full"></div>

			<SearchBar
				onSearchUpdate={setSearchTerm}
				placeholder={'Search..'}
			/>

			{credentials.map((cred) => {
				return (
					<ViewPanel key={cred.game_key}>
						<EventCredentialInfo
							credential={cred}
							secretVisible={visibleSecret === cred.game_key}
							setSecretVisible={(visible) => {
								setVisibleSecret(visible ? cred.game_key : '');
							}}
						/>
					</ViewPanel>
				);
			})}
		</View>
	);
});

export default EventCredentials;
