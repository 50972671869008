import { observer } from 'mobx-react-lite';
import Icon from '@components/core/icon/icon.tsx';
import { IconEnum } from '@components/core/icon/icon-enum.ts';
import LoadingSpinner from '@components/core/loading-spinner/loading-spinner.tsx';
import creatorStore from '@store/creator-store.ts';
import { CreatorType } from '@/schemas/creator-schema.ts';

interface ExperienceCreatorProps {
	creatorId: number;
}

const ExperienceCreator = observer(function ExperienceCreator(
	props: ExperienceCreatorProps
) {
	const creator = creatorStore.getCreatorById(props.creatorId);

	return (
		<div className={'flex items-center justify-start w-full max-w-fit'}>
			{creatorStore.initialized && !!creator && (
				<>
					<div className={'truncate'}>{creator.name}</div>
					<span title={creator.type} className={'pl-2'}>
						<Icon
							icon={
								creator.type === CreatorType.GROUP
									? IconEnum.GROUP
									: IconEnum.USER
							}
							size={
								creator.type === CreatorType.GROUP
									? '24px'
									: '20px'
							}
						/>
					</span>
				</>
			)}

			{(!creatorStore.initialized || !creator) && <LoadingSpinner />}
		</div>
	);
});

export default ExperienceCreator;
