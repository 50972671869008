import { observer } from 'mobx-react-lite';
import React from 'react';
import Button from '@components/core/button/button.tsx';
import { BUTTON_STYLE } from '@components/core/button/button-style-enum.ts';
import modalStore from '@store/modal-store.ts';

interface ModalBaseProps {
	title?: string;
	content: {
		description?: string;
		children?: React.ReactNode;
	};
	onConfirm: () => unknown;
	options?: {
		confirmButtonTitle?: string;
		confirmButtonStyle?: BUTTON_STYLE;
		confirmButtonDisabled?: boolean;
	};
	loadingSubmit?: boolean;
}

const ModalBase = observer(function ModalBase(props: ModalBaseProps) {
	return (
		<div className={'flex flex-col justify-center items-center w-full'}>
			{!!props.title && (
				<div className={'mb-4 font-medium text-center'}>
					{props.title}
				</div>
			)}

			{!!props.content.description && (
				<div className={'mb-6 text-center'}>
					{props.content.description}
				</div>
			)}

			{!!props.content.children && (
				<div className={'mb-6 text-center'}>
					{props.content.children}
				</div>
			)}

			<div className={'flex justify-evenly w-full'}>
				<Button
					title={'Cancel'}
					onClick={() => {
						modalStore.close();
					}}
					disabled={!!props.loadingSubmit}
				/>

				<Button
					title={props.options?.confirmButtonTitle ?? 'Confirm'}
					onClick={async () => {
						await props.onConfirm();
						if (props.loadingSubmit === undefined) {
							modalStore.close();
						}
					}}
					buttonStyle={
						props.options?.confirmButtonStyle ??
						BUTTON_STYLE.PRIMARY
					}
					disabled={props.options?.confirmButtonDisabled}
					loading={!!props.loadingSubmit}
				/>
			</div>
		</div>
	);
});

export default ModalBase;
