import { observer } from 'mobx-react-lite';
import ModalBase from '@components/modal/modal-base.tsx';
import InputText from '@components/core/input-text/input-text.tsx';
import { ChangeEvent, useState } from 'react';
import Checkbox from '@components/core/checkbox/checkbox.tsx';
import Tooltip from '@components/core/tooltip/tooltip.tsx';
import Icon from '@components/core/icon/icon.tsx';
import { IconEnum } from '@components/core/icon/icon-enum.ts';
import authStore from '@store/auth-store.ts';
import { UserPermission } from '@/schemas/user-schema.ts';
import modalStore from '@store/modal-store.ts';

interface ExperienceTagsCreateNewModalProps {
	onSubmit: (tagName: string, global: boolean) => Promise<unknown>;
}

const ExperienceTagsCreateNewModal = observer(
	function ExperienceTagsCreateNewModal(
		props: ExperienceTagsCreateNewModalProps
	) {
		const [tagName, setTagName] = useState('');
		const [curated, setCurated] = useState(false);
		const [loading, setLoading] = useState(false);

		const handleTagNameChange = (event: ChangeEvent<HTMLInputElement>) => {
			setTagName(event.target.value);
		};

		const submit = async () => {
			setLoading(true);
			await props.onSubmit(tagName, curated);
			setLoading(false);
			modalStore.close();
		};

		return (
			<ModalBase
				title={`New Tag`}
				content={{
					children: (
						<div
							className={
								'flex flex-col justify-center items-center gap-4'
							}
						>
							<InputText
								label={'Tag Name'}
								value={tagName}
								onInput={handleTagNameChange}
								options={{ noMargin: true, width: '18rem' }}
							/>

							{authStore.havePermission(
								UserPermission.EDIT_TAG
							) && (
								<div
									className={
										'flex justify-center items-center gap-3'
									}
								>
									<Checkbox
										label={'Curated Tag'}
										initialState={curated}
										onChange={setCurated}
									/>

									<Tooltip
										text={
											'A curated tag are available for everyone to view and all editors can update or delete the tag.'
										}
										direction={'right'}
										widthClass={'w-64'}
									>
										<Icon
											icon={IconEnum.HELP}
											className={
												'text-gray-400 dark:text-gray-300 outline outline-gray-500 dark:outline-white rounded-full cursor-help'
											}
										/>
									</Tooltip>
								</div>
							)}
						</div>
					),
				}}
				onConfirm={() => {
					void submit();
				}}
				options={{
					confirmButtonTitle: 'Create',
					confirmButtonDisabled: !tagName.length,
				}}
				loadingSubmit={loading}
			/>
		);
	}
);

export default ExperienceTagsCreateNewModal;
