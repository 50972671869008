import { observer } from 'mobx-react-lite';
import { ReactNode } from 'react';
import ClassString from '@utils/class-string.ts';

interface ExperienceDetailsInfoPanel {
	title: string;
	content: string | number | ReactNode;
	disableTruncate?: boolean;
	className?: string;
}

const ExperienceDetailsInfoPanel = observer(function ExperienceDetailsInfoPanel(
	props: ExperienceDetailsInfoPanel
) {
	const truncate =
		typeof props.content === 'string' || typeof props.content === 'number';

	return (
		<div
			className={ClassString({
				static: 'flex justify-start items-center bg-white dark:bg-gray-550 p-3 border border-gray-300 dark:border-gray-500 rounded-lg max-w-md min-w-40 w-fit h-20 shadow-lg',
				dynamic: {},
				custom: props.className,
			})}
		>
			<div
				className={ClassString({
					static: 'w-full',
					dynamic: { truncate: truncate && !props.disableTruncate },
				})}
			>
				<div
					className={
						'text-lg font-semibold dark:font-normal text-blue-500 dark:text-blue-100'
					}
				>
					{props.title}
				</div>
				<div
					className={
						'text-2xl flex items-center min-w-48 justify-start w-full'
					}
				>
					<div
						className={ClassString({
							static: 'w-full',
							dynamic: {
								truncate: truncate && !props.disableTruncate,
							},
						})}
					>
						{props.content}
					</div>
				</div>
			</div>
		</div>
	);
});

export default ExperienceDetailsInfoPanel;
