import { observer } from 'mobx-react-lite';
import ViewTabPanel from '@components/view/view-tab-panel.tsx';
import { CCUDataSegment } from '@store/place-stats-store.ts';
import AverageTagCCU from '@components/data/average-tag-ccu/average-tag-ccu.tsx';
import { useState } from 'react';
import { PopulatedPlaceTag } from '@/schemas/plage-tag-schema.ts';

interface ExperienceTagDetailsCcuProps {
	tag: PopulatedPlaceTag;
}

const ExperienceTagDetailsCcu = observer(function ExperienceTagDetailsCcu(
	props: ExperienceTagDetailsCcuProps
) {
	const [ccuChartState, setCCUChartState] = useState(CCUDataSegment.ACTIVE);

	return (
		<ViewTabPanel
			tabs={[
				{
					key: CCUDataSegment.ACTIVE,
					title: 'Active',
					tooltip:
						'Only include experiences with active players at the specific timestamp.',
				},
				{
					key: CCUDataSegment.TOP_FIVE,
					title: 'Top Five',
					tooltip: (
						<div>
							{
								'Only include the top 5 experiences of the tag in terms of current player count.'
							}
							<ul className="pl-5 mt-1 list-disc">
								{[...props.tag.places]
									.sort(
										(a, b) =>
											b.players_online - a.players_online
									)
									.slice(0, 5)
									.map((exp) => {
										return (
											<li key={exp.place_id}>
												{exp.canonical_name}
											</li>
										);
									})}
							</ul>
						</div>
					),
				},
				{
					key: CCUDataSegment.ALL,
					title: 'All',
					tooltip:
						'Include all experiences that was created at or after each specific timestamp.',
				},
			]}
			onTabChange={(tab) => {
				setCCUChartState(tab);
			}}
		>
			<AverageTagCCU tag={props.tag} state={ccuChartState} />
		</ViewTabPanel>
	);
});

export default ExperienceTagDetailsCcu;
