import { Experience } from '@/schemas/experience-schema.ts';
import { PlaceTag } from '@/schemas/plage-tag-schema.ts';
import { EventCredential } from '@/schemas/event-credential-schema.ts';

class SearchService {
	experience(place: Experience, searchTerm: string): boolean {
		const loweredSearchTerm = searchTerm.toLowerCase();
		const searchFields = [
			place.name,
			place.canonical_name,
			place.universe_id.toString(),
			place.place_id.toString(),
		];

		return searchFields.some((field) =>
			field.toLowerCase().includes(loweredSearchTerm)
		);
	}

	tag(tag: PlaceTag, searchTerm: string): boolean {
		const loweredSearchTerm = searchTerm.toLowerCase();
		return tag.name.toLowerCase().includes(loweredSearchTerm);
	}

	eventCredential(credential: EventCredential, searchTerm: string): boolean {
		const loweredSearchTerm = searchTerm.toLowerCase();
		const searchFields = [
			credential.comment,
			credential.game_key,
			credential.place_id.toString(),
		];

		return searchFields.some((field) =>
			field.toLowerCase().includes(loweredSearchTerm)
		);
	}
}

const searchService = new SearchService();
export default searchService;
