import { observer } from 'mobx-react-lite';
import View from '@components/view/view.tsx';
import InputText from '@components/core/input-text/input-text.tsx';
import React, { useEffect, useState } from 'react';
import currencyStore from '@store/currency-store.ts';
import EditableText from '@components/core/editable-text/editable-text.tsx';
import {
	CurrencyEnum,
	getCurrencySymbol,
	toCurrencyString,
} from '@utils/currency-string.ts';
import { IconEnum } from '@components/core/icon/icon-enum.ts';
import ViewPanel from '@components/view/view-panel.tsx';
import TGSTable, { CustomColDef } from '@components/core/table/table.tsx';
import { ValueFormatterParams } from 'ag-grid-community';

interface RoboxCurrency {
	id: CurrencyEnum;
	amount: number;
}

const ColumnDef: CustomColDef<RoboxCurrency>[] = [
	{
		headerName: 'Currency',
		field: 'id',
		sortable: false,
		resizable: false,
		flex: 1,
	},
	{
		headerName: '1 Day',
		field: 'amount',
		valueFormatter: (
			params: ValueFormatterParams<RoboxCurrency, number>
		) => {
			if (!params?.node?.data) {
				return '';
			}

			const currency = params.node.data;
			return toCurrencyString(currency.id, currency.amount);
		},
		sortable: false,
		resizable: false,
		flex: 1,
	},
	{
		headerName: '1 Week',
		field: 'amount',
		valueFormatter: (
			params: ValueFormatterParams<RoboxCurrency, number>
		) => {
			if (!params?.node?.data) {
				return '';
			}

			const currency = params.node.data;
			return toCurrencyString(currency.id, currency.amount * 7);
		},
		sortable: false,
		resizable: false,
		flex: 1,
	},
	{
		headerName: '1 Month',
		field: 'amount',
		valueFormatter: (
			params: ValueFormatterParams<RoboxCurrency, number>
		) => {
			if (!params?.node?.data) {
				return '';
			}

			const currency = params.node.data;
			return toCurrencyString(currency.id, currency.amount * 30.5);
		},
		sortable: false,
		resizable: false,
		flex: 1,
	},
	{
		headerName: '1 Year',
		field: 'amount',
		valueFormatter: (
			params: ValueFormatterParams<RoboxCurrency, number>
		) => {
			if (!params?.node?.data) {
				return '';
			}

			const currency = params.node.data;
			return toCurrencyString(currency.id, currency.amount * 365);
		},
		sortable: false,
		resizable: false,
		flex: 1,
	},
];

const robuxToDollar = (amount: number) => {
	return (0.0035 * amount).toFixed(getFractionDigits(amount));
};

const dollarToRobux = (amount: number) => {
	return Math.round(285.7142857 * amount).toString();
};

const getFractionDigits = (decimalNumber: number) => {
	if (!decimalNumber) {
		return 2;
	}

	if (decimalNumber < 1) {
		return 6;
	}

	if (decimalNumber < 20) {
		return 4;
	}

	return 2;
};

const RobuxConverter = observer(function RobuxConverter() {
	const [displayMultiple, setDisplayMultiple] = useState(
		window.innerWidth <= 760
	);
	const [robux, setRobux] = useState('');
	const [usd, setUsd] = useState('0.00');
	const [euro, setEuro] = useState('0.00');
	const [sek, setSek] = useState('0.00');

	useEffect(() => {
		const updateDisplayMultiple = () => {
			if (window.innerWidth > 760) {
				setDisplayMultiple(false);
			} else {
				setDisplayMultiple(true);
			}
		};

		window.addEventListener('resize', updateDisplayMultiple);

		return () =>
			window.removeEventListener('resize', updateDisplayMultiple);
	}, []);

	const handleRobuxInput = (event: React.ChangeEvent<HTMLInputElement>) => {
		const robuxString = event.target.value.replace(/\s/g, '');
		setRobux(robuxString);
		const usdString = robuxToDollar(+robuxString);
		setUsd(usdString);
		if (currencyStore.usdRates) {
			setEuro((+usdString * currencyStore.usdRates.rates.EUR).toFixed(2));
			setSek((+usdString * currencyStore.usdRates.rates.SEK).toFixed(2));
		}
	};

	const handleUSDInput = (usdString: string) => {
		const trimmedUsd = usdString.replace(/\s/g, '');
		setUsd(trimmedUsd);
		const robuxString = dollarToRobux(+trimmedUsd);
		setRobux(robuxString);
		if (currencyStore.usdRates) {
			setEuro((+usdString * currencyStore.usdRates.rates.EUR).toFixed(2));
			setSek((+usdString * currencyStore.usdRates.rates.SEK).toFixed(2));
		}
	};

	const tableData: RoboxCurrency[] = [
		{
			id: CurrencyEnum.USD,
			amount: +usd,
		},
	];

	if (currencyStore.usdRates) {
		tableData.push(
			...[
				{
					id: CurrencyEnum.EUR,
					amount: +euro,
				},
				{
					id: CurrencyEnum.SEK,
					amount: +sek,
				},
			]
		);
	}

	return (
		<View
			viewInfo={{
				title: 'Robux Converter',
				icon: IconEnum.TOOLS,
				iconSize: '28px',
				breadCrumbs: [{ title: 'Tools' }],
			}}
			className={'max-w-screen-lg'}
			hideHeader
		>
			<ViewPanel className={'flex flex-wrap justify-around'}>
				<div className={'grid grid-cols-2 w-full'}>
					<div
						className={
							'flex flex-col justify-center items-center mb-2 lg:mb-8'
						}
					>
						<div
							className={
								'flex text-center items-center lg:max-w-96'
							}
						>
							Convert Robux to USD. If daily currency conversion
							rates are available, USD will also converted to EUR
							and SEK.
						</div>
					</div>

					<div className={'flex flex-col items-center mb-4 lg:mb-8'}>
						<InputText
							label={'Robux'}
							value={robux}
							onInput={handleRobuxInput}
						></InputText>

						<div className={'text-4xl flex mt-1'}>
							<div className={'mr-2 font-bold'}>USD</div>
							{getCurrencySymbol(CurrencyEnum.USD)}
							<EditableText
								text={usd}
								onTextChange={handleUSDInput}
								resetOnEmpty
								iconSize={'18px'}
							/>
						</div>
					</div>
				</div>

				{!displayMultiple && (
					<div className={'w-full'}>
						<TGSTable
							columnDefs={ColumnDef}
							rowData={tableData}
							tableName={'robox-currency-table'}
							disablePagination
						/>
					</div>
				)}

				{displayMultiple && (
					<div className={'w-full flex flex-col gap-4'}>
						<TGSTable
							columnDefs={[ColumnDef[0], ColumnDef[1]]}
							rowData={tableData}
							tableName={'robox-currency-table'}
							disablePagination
						/>

						<TGSTable
							columnDefs={[ColumnDef[0], ColumnDef[2]]}
							rowData={tableData}
							tableName={'robox-currency-table'}
							disablePagination
						/>

						<TGSTable
							columnDefs={[ColumnDef[0], ColumnDef[3]]}
							rowData={tableData}
							tableName={'robox-currency-table'}
							disablePagination
						/>

						<TGSTable
							columnDefs={[ColumnDef[0], ColumnDef[4]]}
							rowData={tableData}
							tableName={'robox-currency-table'}
							disablePagination
						/>
					</div>
				)}
			</ViewPanel>
		</View>
	);
});

export default RobuxConverter;
