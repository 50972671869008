import { makeAutoObservable, runInAction } from 'mobx';
import { RefObject } from 'react';

interface OptionsMenu {
	buttons: {
		title: string;
		onClick: () => unknown;
	}[];
	ref: RefObject<HTMLDivElement>;
	width?: string;
}

class OptionsMenuStore {
	private _options: OptionsMenu | undefined;
	constructor() {
		makeAutoObservable(this);
	}

	open(options: OptionsMenu) {
		runInAction(() => {
			this._options = options;
		});
	}

	close() {
		runInAction(() => {
			this._options = undefined;
		});
	}

	get options() {
		return this._options;
	}
}

const optionsMenuStore = new OptionsMenuStore();
export default optionsMenuStore;
