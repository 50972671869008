import { observer } from 'mobx-react-lite';
import { SlimExperience } from '@/schemas/experience-schema.ts';
import GroupBy from '@utils/group-by.ts';
import TopCreatorsListEnum from '@views/overview/view-components/top-creators-list-enum.ts';
import TGSTable, { CustomColDef } from '@components/core/table/table.tsx';
import { CustomCellRendererProps } from 'ag-grid-react';
import NavigationButton from '@components/navigation/navigation-button/navigation-button.tsx';
import formatNumberWithSuffix from '@utils/format-number-with-suffix.ts';
import creatorStore from '@store/creator-store.ts';

interface TopCreatorListProps {
	places: SlimExperience[];
	listBy: TopCreatorsListEnum;
}

interface CreatorPlaces {
	id: number;
	name: string;
	totalPlayers: number;
	totalVisits: number;
}

const SortTopCreatorsList = (
	creatorPlaces: CreatorPlaces[],
	listBy: TopCreatorsListEnum
) => {
	switch (listBy) {
		case TopCreatorsListEnum.BY_TOTAL_VISITS:
			return creatorPlaces.sort((a, b) => b.totalVisits - a.totalVisits);
		case TopCreatorsListEnum.BY_CURRENT_PLAYERS:
			return creatorPlaces.sort(
				(a, b) => b.totalPlayers - a.totalPlayers
			);
	}
};

const TopCreatorsList = observer(function TopCreatorsList(
	props: TopCreatorListProps
) {
	const placesGroupedByCreator = GroupBy(
		props.places,
		(place: SlimExperience) => place.creator_id
	);

	const creators = Object.entries(
		Object.fromEntries(placesGroupedByCreator.entries())
	).map((entry) => {
		return {
			id: entry[1][0].creator_id,
			name: '',
			totalPlayers: entry[1].reduce(
				(a, b) => a + (b.players_online ?? 0),
				0
			),
			totalVisits: entry[1].reduce(
				(a, b) => a + (b.total_visits ?? 0),
				0
			),
		} as CreatorPlaces;
	});

	const top10Creators: CreatorPlaces[] = SortTopCreatorsList(
		creators,
		props.listBy
	)
		.slice(0, 10)
		.map((topCreator) => {
			return {
				...topCreator,
				name: creatorStore.getCreatorById(topCreator.id)?.name ?? '',
			};
		});

	const ColumnDef: CustomColDef<CreatorPlaces>[] = [
		{
			minWidth: 55,
			maxWidth: 55,
			cellRenderer: (params: CustomCellRendererProps) => {
				const placement = (params.node.rowIndex ?? 0) + 1;
				return (
					<div
						className={
							'text-2xl font-bold flex justify-center items-center'
						}
					>
						{placement}
					</div>
				);
			},
			cellStyle: {
				display: 'flex',
				alignItems: 'center',
			},
			resizable: false,
			sortable: false,
		},
		{
			headerName: 'Creator',
			field: 'name',
			cellRenderer: (params: CustomCellRendererProps) => {
				const creatorPlaces = params.node.data as CreatorPlaces;
				return (
					<NavigationButton path={`/creators/${creatorPlaces.id}`}>
						{creatorPlaces.name}
					</NavigationButton>
				);
			},
			resizable: false,
			sortable: false,
			cellStyle: {
				display: 'flex',
				alignItems: 'center',
				paddingLeft: '25px',
				paddingRight: '0',
			},
			headerClass: '!pl-7',
			flex: 1,
		},
	];

	switch (props.listBy) {
		case TopCreatorsListEnum.BY_TOTAL_VISITS:
			ColumnDef.push({
				headerName: 'Total Visits',
				field: 'totalVisits',
				valueFormatter: (params) => {
					const totalVisits = params.value as number;
					return `${formatNumberWithSuffix(totalVisits)}+`;
				},
				cellStyle: {
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
				},
				minWidth: 120,
				maxWidth: 120,
				resizable: false,
				sortable: false,
			});
			break;
		case TopCreatorsListEnum.BY_CURRENT_PLAYERS:
			ColumnDef.push({
				headerName: 'Players Online',
				field: 'totalPlayers',
				valueFormatter: (params) => {
					const totalPlayers = params.value as number;
					return new Intl.NumberFormat(navigator.language).format(
						totalPlayers
					);
				},
				cellStyle: {
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
				},
				minWidth: 130,
				maxWidth: 130,
				resizable: false,
				sortable: false,
			});
			break;
	}

	return (
		<TGSTable
			columnDefs={ColumnDef}
			rowData={top10Creators}
			tableName={'top-5-creators-place-amounts'}
			disablePagination
		/>
	);
});

export default TopCreatorsList;
