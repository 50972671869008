const timeAgo = (date: Date): string => {
	const now = new Date();
	const diff = now.getTime() - date.getTime(); // difference in milliseconds

	const minute = 60 * 1000; // milliseconds in a minute
	const hour = 60 * minute; // milliseconds in an hour
	const day = 24 * hour; // milliseconds in a day
	const month = 30 * day; // approximately 30 days in a month
	const year = 12 * month; // 12 months in a year

	if (diff < hour) {
		const minutesAgo = Math.floor(diff / minute);
		return minutesAgo === 1
			? `${minutesAgo} minute ago`
			: `${minutesAgo} minutes ago`;
	} else if (diff < day) {
		const hoursAgo = Math.floor(diff / hour);
		return hoursAgo === 1
			? `${hoursAgo} hour ago`
			: `${hoursAgo} hours ago`;
	} else if (diff < 31 * day) {
		const daysAgo = Math.floor(diff / day);
		return daysAgo === 1 ? `${daysAgo} day ago` : `${daysAgo} days ago`;
	} else if (diff < 12 * month) {
		const monthsAgo = Math.floor(diff / month);
		return monthsAgo === 1
			? `${monthsAgo} month ago`
			: `${monthsAgo} months ago`;
	} else {
		const yearsAgo = Math.floor(diff / year);
		return yearsAgo === 1
			? `${yearsAgo} year ago`
			: `${yearsAgo} years ago`;
	}
};

export default timeAgo;
