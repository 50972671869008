import { observer } from 'mobx-react-lite';
import React, { CSSProperties, useEffect, useState } from 'react';
import ClassString from '@utils/class-string.ts';
import Icon from '@components/core/icon/icon.tsx';
import { IconEnum } from '@components/core/icon/icon-enum.ts';
import { useResizeDetector } from 'react-resize-detector';

interface ExpansionPanelProps {
	title: string;
	children: React.ReactNode;
	viewPanel?: boolean;
	className?: string;
	classNameTitle?: string;
	icon?: ExpansionPanelIcon;
	colorOnHover?: boolean;
	selected?: boolean;
	titleOptions?: {
		titleHeight?: string;
		titleBorderRadius?: boolean;
		padding?: boolean;
	};
	overflowVisible?: boolean;
	state?: 'expanded' | 'closed';
	initialState?: 'expanded' | 'closed';
	onStateChange?: (state: boolean) => unknown;
}

export interface ExpansionPanelIcon {
	icon: IconEnum;
	size?: string;
}

const zeroHeight = '0px';

const ExpansionPanel = observer(function ExpansionPanel(
	props: ExpansionPanelProps
) {
	const [panelHeight, setPanelHeight] = useState(
		props.initialState === 'expanded' ? 'auto' : zeroHeight
	);
	const { height, ref } = useResizeDetector({
		handleWidth: false,
	});

	useEffect(() => {
		if (props.state) {
			if (props.state === 'expanded') {
				setPanelHeight(`${height ?? 0}px`);
			} else {
				setPanelHeight(zeroHeight);
			}
		}
	}, [height, props.state]);

	useEffect(() => {
		if (panelHeight === 'auto' && !!height) {
			setPanelHeight(`${height}px`);
		}
	}, [height, panelHeight]);

	return (
		<div
			className={ClassString({
				static: 'h-expansion-panel-open transition-all ease-transition-curve overflow-hidden',
				dynamic: {
					'overflow-visible': props.overflowVisible,
				},
				custom: props.className,
			})}
			style={
				{
					'--panel-height': panelHeight,
					'--title-height': props.titleOptions?.titleHeight ?? '34px',
				} as CSSProperties
			}
		>
			<div
				className={ClassString({
					static: 'min-h-expansion-panel-title flex items-center justify-between px-4 select-none cursor-pointer',
					dynamic: {
						'hover:bg-blue-500': props.colorOnHover,
						'bg-blue-500':
							props.selected && panelHeight === zeroHeight,
						'rounded-md': props.titleOptions?.titleBorderRadius,
						'text-2xl': props.viewPanel,
						'px-8': props.titleOptions?.padding,
					},
					custom: props.classNameTitle,
				})}
				onClick={() => {
					if (panelHeight === zeroHeight) {
						setPanelHeight(`${height ?? 0}px`);
					} else {
						setPanelHeight(zeroHeight);
					}

					if (props.onStateChange) {
						props.onStateChange(panelHeight === zeroHeight);
					}
				}}
			>
				<div className={'truncate flex items-center'}>
					{!!props.icon && (
						<div className={'mr-2 flex items-center'}>
							<Icon
								icon={props.icon.icon}
								size={props.icon.size ?? '18px'}
							/>
						</div>
					)}
					{props.title}
				</div>
				<Icon
					icon={IconEnum.CHEVRON_RIGHT}
					className={ClassString({
						static: 'transition-all ease-transition-curve',
						dynamic: {
							'rotate-90': panelHeight !== zeroHeight,
						},
					})}
					size={'26px'}
				/>
			</div>
			<div ref={ref} className={'flex flex-col'}>
				{props.children}
			</div>
		</div>
	);
});

export default ExpansionPanel;
