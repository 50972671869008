import { observer } from 'mobx-react-lite';
import { Creator, CreatorType } from '@/schemas/creator-schema.ts';
import { useState } from 'react';
import Icon from '@components/core/icon/icon.tsx';
import { IconEnum } from '@components/core/icon/icon-enum.ts';
import { CreatorThumbnailSize } from '@components/data/creator-thumbnail/creator-thumbnail-size.ts';

interface CreatorThumbnailProps {
	creator: Creator;
	size: CreatorThumbnailSize;
	className?: string;
}

const CreatorThumbnail = observer(function CreatorThumbnail(
	props: CreatorThumbnailProps
) {
	const [imgSrc, setImgSrc] = useState<string | undefined>(
		props.creator.type === CreatorType.GROUP
			? `https://tr.rbxcdn.com/180DAY-${props.creator.thumbnail_hash}/${props.size}/Image/Webp/noFilter`
			: `https://tr.rbxcdn.com/30DAY-AvatarHeadshot-${props.creator.thumbnail_hash.toUpperCase()}-Png/${props.size}/AvatarHeadshot/Webp/noFilter`
	);

	const onError = () => {
		setImgSrc(undefined);
	};

	return (
		<>
			{!!imgSrc && (
				<img
					src={imgSrc}
					alt={''}
					className={props.className}
					loading={'lazy'}
					onError={onError}
				/>
			)}

			{!imgSrc && (
				<>
					{props.creator.type === CreatorType.GROUP && (
						<Icon icon={IconEnum.GROUP} size={'2rem'} />
					)}{' '}
					{props.creator.type === CreatorType.USER && (
						<Icon icon={IconEnum.USER} size={'2rem'} />
					)}
				</>
			)}
		</>
	);
});

export default CreatorThumbnail;
