import experienceStore from '@store/experience-store.ts';
import platformStatsStore from '@store/platform-stats-store.ts';
import dailyPickStore from '@store/daily-pick-store.ts';

export function OverviewLoader() {
	void platformStatsStore.loadPlatformCCU();
	void dailyPickStore.loadCurrentDailyPicks().then(() => {
		if (dailyPickStore.currentDailyPick?.places) {
			dailyPickStore.currentDailyPick.places.forEach((place) => {
				void experienceStore.getFullExperience(
					place.place_id.toString()
				);
			});
		}
	});

	return 'ok';
}
