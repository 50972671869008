import { observer } from 'mobx-react-lite';
import SlideToggle from '@components/core/slide-toggle/slide-toggle.tsx';
import themeStore from '@store/theme-store.ts';
import { IconEnum } from '@components/core/icon/icon-enum.ts';

interface ThemeSelectorProps {
	className?: string;
}

const ThemeSelector = observer(function ThemeSelector(
	props: ThemeSelectorProps
) {
	return (
		<SlideToggle
			initialState={themeStore.darkTheme}
			state={themeStore.darkTheme}
			onToggle={(on) => {
				themeStore.setTheme(on ? 'dark' : 'light');
			}}
			icons={{
				off: IconEnum.LIGHT_MODE,
				on: IconEnum.DARK_MODE,
			}}
			className={props.className}
		/>
	);
});

export default ThemeSelector;
