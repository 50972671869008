import { HashParamMap, stringToHashParamKey } from '@/enums/hash-params.ts';

class HashQueryParamService {
	get(): HashParamMap {
		const hashParams = new URLSearchParams(window.location.hash.slice(1));
		const params: HashParamMap = {};

		hashParams.forEach((value, key) => {
			const typedKey = stringToHashParamKey(key);

			params[typedKey] = value;
		});

		return params;
	}

	set(newParams: HashParamMap): void {
		const hashParams = new URLSearchParams();

		for (const key in newParams) {
			if (Object.prototype.hasOwnProperty.call(newParams, key)) {
				const typedKey = stringToHashParamKey(key);

				if (newParams[typedKey]) {
					hashParams.set(key, newParams[typedKey]);
				}
			}
		}

		window.location.hash = hashParams.toString();
	}

	extendCurrent(newParams: HashParamMap): string {
		const extended = { ...this.get(), ...newParams };

		const hashParams = new URLSearchParams();

		for (const key in extended) {
			const typedKey = stringToHashParamKey(key);

			if (Object.prototype.hasOwnProperty.call(extended, key)) {
				if (extended[typedKey]) {
					hashParams.set(key, extended[typedKey]);
				}
			}
		}

		return hashParams.toString();
	}

	create(newParams: HashParamMap) {
		const hashParams = new URLSearchParams();

		for (const key in newParams) {
			const typedKey = stringToHashParamKey(key);

			if (Object.prototype.hasOwnProperty.call(newParams, key)) {
				if (newParams[typedKey]) {
					hashParams.set(key, newParams[typedKey]);
				}
			}
		}

		return hashParams.toString();
	}
}

const hashQueryParamService = new HashQueryParamService();
export default hashQueryParamService;
