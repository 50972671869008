import { observer } from 'mobx-react-lite';
import View from '@components/view/view.tsx';
import ViewPanel from '@components/view/view-panel.tsx';
import { IconEnum } from '@components/core/icon/icon-enum.ts';
import ExpansionInput from '@components/core/expansion-input/expansion-input.tsx';
import { useEffect, useState } from 'react';
import dailyPickStore from '@store/daily-pick-store.ts';
import { DailyPicks as DailyPicksType } from '@/schemas/daily-picks-schema.ts';
import { Link } from 'react-router-dom';
import { FullExperience } from '@/schemas/experience-schema.ts';
import thumbnailStore from '@store/thumbnail-store.ts';
import getOrdinalSuffix from '@utils/get-ordinal-suffix.ts';
import Button from '@components/core/button/button.tsx';
import { ThumbnailType } from '@/schemas/thumbnail-schema.ts';
import PlaceBanner from '@components/data/place-banner/place-banner.tsx';
import { PlaceBannerSize } from '@components/data/place-banner/place-banner-constants.ts';

const dailyPickReleaseDate = new Date('2024-09-20');
const currentYear = new Date().getFullYear();

const listYearsFromGivenDate = (startDate: Date): string[] => {
	const startYear = startDate.getFullYear();

	if (startYear === currentYear) {
		return [currentYear.toString()];
	}

	const years: string[] = [];
	for (let year = startYear; year <= currentYear; year++) {
		years.push(`${year}`);
	}

	return years;
};

const getISOWeekStartAndEndDate = (
	year: number,
	week: number
): { startDate: Date; endDate: Date } => {
	// Create a date representing January 4 of the given year (guaranteed to be in the first ISO week)
	const january4 = new Date(year, 0, 4);
	// Get the ISO week start by calculating the Monday of the first ISO week
	const dayOfWeek = january4.getDay(); // 0 = Sunday, 1 = Monday, ..., 6 = Saturday
	const dayOffset = (dayOfWeek === 0 ? -6 : 1) - dayOfWeek; // Adjust to the previous Monday if needed
	const firstISOWeekMonday = new Date(january4);
	firstISOWeekMonday.setDate(january4.getDate() + dayOffset);

	// Calculate the start date of the specified week
	const startDate = new Date(firstISOWeekMonday);
	startDate.setDate(firstISOWeekMonday.getDate() + (week - 1) * 7);
	startDate.setHours(23);
	startDate.setMinutes(59);

	// Calculate the end date by adding six days to the start date
	const endDate = new Date(startDate);
	endDate.setDate(startDate.getDate() + 6);
	const now = new Date();
	if (endDate.getTime() > now.getTime()) {
		endDate.setFullYear(now.getFullYear());
		endDate.setMonth(now.getMonth());
		endDate.setDate(now.getDate());
	}
	endDate.setHours(23);
	endDate.setMinutes(59);

	// Must be adjusted after end date is set
	if (startDate.getTime() < dailyPickReleaseDate.getTime()) {
		startDate.setFullYear(dailyPickReleaseDate.getFullYear());
		startDate.setMonth(dailyPickReleaseDate.getMonth());
		startDate.setDate(dailyPickReleaseDate.getDate());
	}

	return { startDate, endDate };
};

const getISOWeeksBetweenDatesSameYear = (
	startDate: Date,
	endDate: Date
): number[] => {
	const weeks: number[] = [];
	const current = new Date(startDate);

	// Helper function to calculate ISO week number
	function getISOWeek(date: Date): number {
		const tempDate = new Date(date);
		tempDate.setHours(0, 0, 0, 0);

		// Set to the nearest Thursday (ISO 8601 rule)
		tempDate.setDate(
			tempDate.getDate() + 3 - ((tempDate.getDay() + 6) % 7)
		);

		const yearStart = new Date(tempDate.getFullYear(), 0, 4);
		return Math.ceil(
			((tempDate.getTime() - yearStart.getTime()) / 86400000 + 1) / 7
		);
	}

	// Loop through each week in the range
	while (current <= endDate) {
		const week = getISOWeek(current);
		if (!weeks.includes(week)) {
			weeks.push(week);
		}

		// Move to the next week
		current.setDate(current.getDate() + 7);
	}

	// Ensure the end week's number is included if it is not already
	const lastWeek = getISOWeek(endDate);
	if (!weeks.includes(lastWeek)) {
		weeks.push(lastWeek);
	}

	return weeks;
};

function getCurrentISOWeek(): number {
	const date = new Date();

	// Set date to the nearest Thursday (to match ISO week rules)
	const dayOfWeek = date.getDay(); // 0 = Sunday, 1 = Monday, ..., 6 = Saturday
	const dayOffset = (dayOfWeek === 0 ? -6 : 1) - dayOfWeek;
	date.setDate(date.getDate() + dayOffset + 3);

	// Calculate the ISO week number
	const firstThursday = new Date(date.getFullYear(), 0, 4);
	return Math.ceil(
		((date.getTime() - firstThursday.getTime()) / 86400000 +
			firstThursday.getDay() +
			1) /
			7
	);
}

const loadDailyPicks = async (startDate: Date, endDate: Date) => {
	await dailyPickStore.loadDailyPicks(startDate, endDate);
	const expIds: number[] = [];
	const picks = dailyPickStore.getDailyPicks(startDate, endDate);
	for (const pick of picks) {
		pick.places.forEach((exp) => {
			expIds.push(exp.place_id);
		});
	}
	await thumbnailStore.loadThumbnailsById(expIds, ThumbnailType.BANNER);
};

const isToday = (date: Date) => {
	const today = new Date();
	return (
		date.getDate() === today.getDate() &&
		date.getMonth() === today.getMonth() &&
		date.getFullYear() === today.getFullYear()
	);
};

const getDayOfWeek = (date: Date): string => {
	const daysOfWeek = [
		'Sunday',
		'Monday',
		'Tuesday',
		'Wednesday',
		'Thursday',
		'Friday',
		'Saturday',
	];
	return daysOfWeek[date.getDay()];
};

const currentWeek = getCurrentISOWeek();

const formatDateWithOrdinal = (date: Date): string => {
	const options: Intl.DateTimeFormatOptions = {
		month: 'long',
		day: 'numeric',
		year: 'numeric',
	};
	const dateParts = date.toLocaleDateString('en-US', options).split(' ');

	// Extract the day and add the ordinal suffix
	const day = parseInt(dateParts[1], 10);
	const dayWithOrdinal = day + getOrdinalSuffix(day);

	return `${dateParts[0]} ${dayWithOrdinal}`;
};

const DailyPicks = observer(function DailyPicks() {
	const [selectedYear, setSelectedYear] = useState(currentYear.toString());
	const [yearValue, setYearValue] = useState(currentYear.toString());

	const [selectedWeek, setSelectedWeek] = useState(currentWeek.toString());
	const [weekValue, setWeekValue] = useState(currentWeek.toString());

	const years = listYearsFromGivenDate(dailyPickReleaseDate);
	const weeks = getISOWeeksBetweenDatesSameYear(
		+selectedYear <= currentYear
			? dailyPickReleaseDate
			: dailyPickReleaseDate,
		new Date()
	);

	useEffect(() => {
		if (selectedYear && selectedWeek) {
			const { startDate, endDate } = getISOWeekStartAndEndDate(
				+selectedYear,
				+selectedWeek
			);

			void loadDailyPicks(startDate, endDate);
		}
	}, [selectedWeek, selectedYear]);

	let picks: DailyPicksType[] = [];

	if (selectedYear && selectedWeek) {
		const { startDate, endDate } = getISOWeekStartAndEndDate(
			+selectedYear,
			+selectedWeek
		);
		picks = dailyPickStore.getDailyPicks(startDate, endDate);
	}

	const goToPreviousWeek = () => {
		const weekNumber = +selectedWeek;
		if (weekNumber <= 1) {
			const yearNumber = +selectedYear;
			setSelectedYear((yearNumber - 1).toString());
			setYearValue((yearNumber - 1).toString());
			setSelectedWeek('52');
			setWeekValue('52');
		} else {
			setSelectedWeek((weekNumber - 1).toString());
			setWeekValue((weekNumber - 1).toString());
		}
	};

	const goToNextWeek = () => {
		const weekNumber = +selectedWeek;
		if (weekNumber >= 52) {
			const yearNumber = +selectedYear;
			setSelectedYear((yearNumber + 1).toString());
			setYearValue((yearNumber + 1).toString());
			setSelectedWeek('1');
			setWeekValue('1');
		} else {
			setSelectedWeek((weekNumber + 1).toString());
			setWeekValue((weekNumber + 1).toString());
		}
	};

	return (
		<View
			viewInfo={{
				title: 'Daily Picks',
				icon: IconEnum.DAILY_PICKS,
			}}
		>
			<ViewPanel className={'max-w-screen-md'}>
				<div
					className={
						'flex justify-evenly items-center w-full p-4 gap-12'
					}
				>
					<div className={'text-center max-w-96'}>
						{
							'Daily Picks are a curated selection of daily highlights by Roblox staff. Certain periods might be missing daily picks due to events such as "The Haunt".'
						}
					</div>

					<ExpansionInput
						label={'Year'}
						value={yearValue}
						onInput={(event) => {
							setYearValue(event.target.value);
						}}
						onBlur={() => {
							if (
								yearValue !== selectedYear &&
								!years.includes(yearValue)
							) {
								setSelectedYear('');
								setYearValue('');
								setSelectedWeek('');
								setWeekValue('');
							} else if (
								yearValue !== selectedYear &&
								years.includes(selectedYear)
							) {
								setSelectedYear(yearValue);
							}
						}}
						scroll={years.length > 9}
						suggestionLimit={9}
						suggestions={years.map((year) => {
							return {
								value: year,
								title: year,
								onClick: () => {
									setSelectedYear(year);
									setYearValue(year);
								},
							};
						})}
						width={'6rem'}
					/>
					<ExpansionInput
						label={'Week'}
						value={weekValue}
						onInput={(event) => {
							setWeekValue(event.target.value);
						}}
						onBlur={() => {
							if (
								weekValue !== selectedWeek &&
								!weeks.includes(+weekValue)
							) {
								setWeekValue('');
							} else if (
								weekValue !== selectedWeek &&
								weekValue.includes(selectedWeek)
							) {
								setSelectedWeek(weekValue);
							}
						}}
						scroll={years.length > 9}
						suggestionLimit={9}
						suggestions={weeks.map((week) => {
							const weekString = week.toString();
							return {
								value: weekString,
								title: weekString,
								onClick: () => {
									setSelectedWeek(weekString);
									setWeekValue(weekString);
								},
							};
						})}
						width={'6rem'}
						disabled={!selectedYear}
					/>
				</div>

				{!!selectedYear && !!selectedWeek && (
					<div
						className={
							'w-full flex justify-center items-center text-3xl my-6 font-medium'
						}
					>
						<div>{`Daily Picks ${selectedYear} Week ${selectedWeek}`}</div>
					</div>
				)}
			</ViewPanel>

			{!!selectedYear && !!selectedWeek && (
				<>
					<div className={'w-full flex justify-between items-center'}>
						<Button
							title={'Previous Week'}
							disabled={
								years[0] === selectedYear &&
								weeks[0] === +selectedWeek
							}
							onClick={goToPreviousWeek}
						/>

						<Button
							title={'Next Week'}
							disabled={
								years[years.length - 1] === selectedYear &&
								weeks[weeks.length - 1] === +selectedWeek
							}
							onClick={goToNextWeek}
						/>
					</div>

					{picks.map((pick, index) => {
						return (
							<ViewPanel key={`pick-${index}`}>
								<div className={'w-full flex-col'}>
									<div
										className={
											'text-2xl mb-4 flex justify-start items-center w-full'
										}
									>
										{`${getDayOfWeek(pick.date)} - ${formatDateWithOrdinal(pick.date)}`}
									</div>

									{!!pick.places.length && (
										<div
											className={
												'grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 justify-center items-start gap-8'
											}
										>
											{pick.places.map((exp) => {
												const slimExp =
													exp as FullExperience;

												return (
													<div
														className={
															'max-w-full flex flex-col justify-center items-center'
														}
														key={`daily-pick-${exp?.place_id}`}
													>
														<Link
															to={`/experiences/${exp?.place_id}`}
															className={
																'flex flex-col justify-center items-center gap-2 hover:bg-blue-500 hover:text-white w-fit p-2 rounded-lg group'
															}
															draggable={false}
														>
															<div
																className={
																	'rounded-md outline outline-1 outline-gray-600 group-hover:outline-gray-300 dark:outline-gray-500 overflow-hidden'
																}
															>
																<PlaceBanner
																	place={
																		slimExp
																	}
																	size={
																		PlaceBannerSize.MEDIUM
																	}
																/>
															</div>

															<div
																className={
																	'w-full flex justify-center items-center text-center'
																}
															>
																{exp?.name}
															</div>
														</Link>
													</div>
												);
											})}
										</div>
									)}

									{!pick.places.length && (
										<div
											className={
												'w-full h-20 flex justify-center items-center'
											}
										>
											{isToday(pick.date)
												? 'No New Daily Picks Yet'
												: 'No Daily Picks'}
										</div>
									)}
								</div>
							</ViewPanel>
						);
					})}
				</>
			)}
		</View>
	);
});

export default DailyPicks;
