import { makeAutoObservable, runInAction } from 'mobx';
import httpFetch from '@services/http-fetch-service.ts';
import toastStore from '@store/toast-store.ts';
import { ToastType } from '@components/service/toast/toast-enums.ts';
import {
	Experience,
	FullExperience,
	FullExperienceResponse,
	FullExperienceResponseSchema,
	SlimExperience,
	SlimExperienceListSchema,
	SlimExperienceResponse,
} from '@/schemas/experience-schema.ts';
import authStore from '@store/auth-store.ts';

export const experienceApi = `${import.meta.env.VITE_SERVER_URL}/api/place`;

export const mapExperienceId = (
	experience: FullExperienceResponse
): FullExperience => {
	return {
		...experience,
		created: new Date(experience.created),
		updated: new Date(experience.updated),
		released: new Date(experience.released),
		players_online: experience.players_online ?? 0,
		total_visits: experience.total_visits ?? 0,
		like_percentage: experience.like_percentage ?? 0,
		canonical_name: experience.canonical_name ?? experience.name,
		creator_id: experience.creator_id ?? 0,
	};
};

export const setExperienceRank = <T extends Experience[]>(
	experiences: T
): T => {
	const experienceRanks: Record<number, number> = {};
	const sortedExperiences = experiences.sort((a, b) => {
		const aPlayersOnline = a.players_online ?? 0;
		const bPlayersOnline = b.players_online ?? 0;
		return bPlayersOnline - aPlayersOnline;
	});
	let currentRank = 1;
	sortedExperiences.forEach((experience, index) => {
		if (
			index === 0 ||
			sortedExperiences[index - 1].players_online !==
				experience.players_online
		) {
			currentRank = index + 1;
		}

		experienceRanks[experience.place_id] = currentRank;
	});

	return sortedExperiences.map((experience) => {
		return {
			...experience,
			rank: experienceRanks[experience.place_id] ?? 0,
		};
	}) as T;
};

export const mapExperiences = (
	experiences: FullExperienceResponse[],
	rankExperiences = false
): FullExperience[] => {
	const mappedExperiences = experiences.map((experience) => {
		return mapExperienceId(experience);
	});

	if (rankExperiences) {
		return setExperienceRank(mappedExperiences);
	}

	return mappedExperiences;
};

export const mapSlimExperiences = (
	experiences: SlimExperienceResponse[]
): SlimExperience[] => {
	const mappedExperiences: SlimExperience[] = experiences.map(
		(experience) => {
			return {
				...experience,
				players_online: experience.players_online ?? 0,
				total_visits: experience.total_visits ?? 0,
				like_percentage: experience.like_percentage ?? 0,
				canonical_name: experience.canonical_name ?? experience.name,
				creator_id: experience.creator_id ?? 0,
			};
		}
	);

	return setExperienceRank(mappedExperiences);
};

export const convertToExperiencePut = (experience: FullExperience) => {
	interface OmittedExperience
		extends Omit<
			FullExperience,
			'players_online' | 'total_visits' | 'like_percentage'
		> {
		players_online?: number;
		total_visits?: number;
		like_percentage?: number;
	}
	const omittedExperience: OmittedExperience = { ...experience };
	delete omittedExperience.players_online;
	delete omittedExperience.total_visits;
	delete omittedExperience.like_percentage;
	return omittedExperience;
};

class ExperienceStore {
	private _slimExperiences: SlimExperience[] = [];
	private _fullExperiences: FullExperience[] = [];

	private _loadingList = false;
	private _initialized = false;
	private _reFetchTimer: Timer | undefined;

	constructor() {
		makeAutoObservable(this);
	}

	async loadSlimExperiences() {
		if (!this._loadingList) {
			runInAction(() => {
				this._loadingList = true;
			});

			const response = await httpFetch.GET(`${experienceApi}?slim`);

			if (response.ok) {
				const loadedExperiences = SlimExperienceListSchema.parse(
					await response.json()
				);
				const mappedExperiences = mapSlimExperiences(loadedExperiences);
				runInAction(() => {
					this._slimExperiences = mappedExperiences;

					this._initialized = true;
					this._loadingList = false;
					this.scheduleSlimExperienceListReFetch();
				});
			} else {
				runInAction(() => {
					this._initialized = true;
					this._loadingList = false;
					this.scheduleSlimExperienceListReFetch();
				});
			}
		}
	}

	async getFullExperience(expId: string) {
		const response = await httpFetch.GET(
			`${experienceApi}/${expId}`,
			true,
			!authStore.isLoggedIn
		);

		if (response.ok) {
			const experienceResponse = FullExperienceResponseSchema.parse(
				await response.json()
			);
			const mappedExperience = mapExperienceId(experienceResponse);
			runInAction(() => {
				const filteredExperienceList = this._fullExperiences.filter(
					(experience) =>
						experience.place_id !== mappedExperience.place_id
				);

				filteredExperienceList.push(mappedExperience);
				this._fullExperiences = filteredExperienceList.sort((a, b) => {
					return a.name.localeCompare(b.name);
				});
			});
		}

		return response;
	}

	async getAndReturnFullExperience(expId: string) {
		const response = await httpFetch.GET(
			`${experienceApi}/${expId}`,
			true,
			!authStore.isLoggedIn
		);

		if (response.ok) {
			const experienceResponse = FullExperienceResponseSchema.parse(
				await response.json()
			);
			const mappedExperience = mapExperienceId(experienceResponse);
			runInAction(() => {
				const filteredExperienceList = this._fullExperiences.filter(
					(experience) =>
						experience.place_id !== mappedExperience.place_id
				);

				filteredExperienceList.push(mappedExperience);
				this._fullExperiences = filteredExperienceList.sort((a, b) => {
					return a.name.localeCompare(b.name);
				});
			});

			return mappedExperience;
		}
	}

	async updateReleaseDate(experience: FullExperience, date: Date) {
		const experiencePut = convertToExperiencePut(experience);
		const updatedExperience = {
			...experiencePut,
			released: date.toISOString(),
		};
		const response = await httpFetch.PUT(
			`${experienceApi}/${experience.place_id}`,
			updatedExperience
		);

		if (response.ok) {
			runInAction(() => {
				this._fullExperiences = this._fullExperiences.map((exp) => {
					if (exp.place_id === experience.place_id) {
						return { ...experience, released: date };
					}
					return exp;
				});
			});

			toastStore.emit(
				'Successfully saved experience release date',
				ToastType.CONFIRM
			);
		}
	}

	async updateCanonicalName(
		experience: FullExperience,
		canonicalName: string
	) {
		const experiencePut = convertToExperiencePut(experience);
		const updatedExperience = {
			...experiencePut,
			canonical_name: canonicalName,
		};
		const response = await httpFetch.PUT(
			`${experienceApi}/${experience.place_id}`,
			updatedExperience
		);

		if (response.ok) {
			runInAction(() => {
				this._fullExperiences = this._fullExperiences.map(
					(arrayExperience) => {
						if (arrayExperience.place_id === experience.place_id) {
							return {
								...experience,
								canonical_name: canonicalName,
							};
						}
						return arrayExperience;
					}
				);
			});

			toastStore.emit(
				'Successfully saved experience canonical name',
				ToastType.CONFIRM
			);
		}
	}

	getFullExperienceById(id: number) {
		return this._fullExperiences.find(
			(experience) => experience.place_id === id
		);
	}

	getSlimExperienceById(id: number) {
		return this._slimExperiences.find(
			(experience) => experience.place_id === id
		);
	}

	get fullExperiences() {
		return this._fullExperiences;
	}

	get slimExperiences() {
		return this._slimExperiences;
	}

	get initialized() {
		return this._initialized;
	}

	async waitForInit(): Promise<void> {
		if (this._initialized) {
			return;
		}

		await new Promise((resolve) => setTimeout(resolve, 250));
		return this.waitForInit();
	}

	// Re-fetch experiences every 10 min due to the DB data updates
	private scheduleSlimExperienceListReFetch() {
		if (!this._reFetchTimer) {
			runInAction(() => {
				this._reFetchTimer = setTimeout(() => {
					runInAction(() => {
						this._initialized = false;
						this._reFetchTimer = undefined;
					});
				}, 600000);
			});
		}
	}
}

const experienceStore = new ExperienceStore();
export default experienceStore;
