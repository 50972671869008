import { observer } from 'mobx-react-lite';
import View from '@components/view/view.tsx';
import { useLoaderData, useLocation } from 'react-router-dom';
import experienceStore from '@store/experience-store.ts';
import placeTagStore from '@store/place-tag-store.ts';
import ExperienceDetailsInfo from '@views/experience-details/view-components/experience-details-info.tsx';
import { ExperienceDetailsLoaderReturn } from '@views/experience-details/experience-details-loader.ts';
import PlaceStats from '@components/data/place-stats/place-stats.tsx';
import { IconEnum } from '@components/core/icon/icon-enum.ts';
import {
	PlaceStatGranularity,
	PlaceStatType,
} from '@store/place-stats-store.ts';
import ViewPanel from '@components/view/view-panel.tsx';
import LightdashDashboards from '@components/data/lightdash-dashboards/lightdash-dashboards.tsx';
import DashboardType from '@components/data/lightdash-dashboards/dashboard-type.ts';
import ExperienceLoadingTimesList from '@components/data/lightdash-dashboards/experience-loading-times-list.ts';
import { DashboardInfoType } from '@components/data/lightdash-dashboards/dashboard-info.ts';
import ExperienceDetailsRanking from '@views/experience-details/view-components/experience-details-ranking.tsx';
import ChartColors from '@components/core/chart/chart-colors.ts';
import ExperienceCcuAndVisits from '@components/data/experience-ccu-and-visits/experience-ccu-and-visits.tsx';
import ExperienceDetailsRankingTable from '@views/experience-details/view-components/experience-details-ranking-table.tsx';
import { useEffect, useState } from 'react';
import { LocationState } from '@components/navigation/navigation-button/navigation-button.tsx';
import postHogService from '@services/posthog-service.ts';
import { PostHogEventType } from '@/enums/posthog-event.ts';
import { ExperienceStatus } from '@/schemas/experience-schema.ts';
import ViewTabPanel from '@components/view/view-tab-panel.tsx';
import { ExperienceCcuAndVisitsState } from '@components/data/experience-ccu-and-visits/experience-ccu-and-visits-state.ts';
import viewStore from '@store/view-store.ts';
import experienceRankingStore from '@store/experience-ranking-store.ts';
import ExperienceDetailsTags from '@views/experience-details/view-components/experience-details-tags.tsx';
import authStore from '@store/auth-store.ts';
import { UserPermission } from '@/schemas/user-schema.ts';
import useScreenBreakpoint from '@hooks/use-screen-breakpoint.tsx';
import { ScreenBreakpoint } from '@/enums/screen-breakpoints.ts';
import ClassString from '@utils/class-string.ts';

const ExperienceDetails = observer(function ExperienceDetails() {
	const locationState = useLocation().state as LocationState;
	const screenBreakpoint = useScreenBreakpoint();

	// Loader handles assuring there is an Experience
	const { experienceId } = useLoaderData() as ExperienceDetailsLoaderReturn;
	const experience = experienceStore.getFullExperienceById(+experienceId);

	const experienceTags = placeTagStore.getGlobalPlaceTagsByPlaceId(
		experience?.place_id ?? 0
	);

	const [currentRankingsTab, setCurrentRankingsTab] = useState('');

	const [ccuChartState, setCCUChartState] = useState(
		ExperienceCcuAndVisitsState.CCU
	);

	const dashboards: DashboardInfoType[] = [
		{
			type: DashboardType.GAME_SPECIFIC_OVERVIEW,
			placeId: experience?.place_id ?? 0,
		},
	];

	if (
		experience &&
		ExperienceLoadingTimesList.includes(experience.place_id)
	) {
		dashboards.push({
			type: DashboardType.EXPERIENCE_LOADING_TIMES,
			experienceCanonicalName: experience.canonical_name,
		});
	}

	useEffect(() => {
		postHogService.capture({
			type: PostHogEventType.NavigateToExperience,
			from: locationState?.from.pathname ?? 'Direct Navigation',
			experience: experience?.canonical_name ?? '?',
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (viewStore.loadingViewData && !experienceRankingStore.loading) {
			viewStore.loadingViewData = false;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [viewStore.loadingViewData, experienceRankingStore.loading]);

	return (
		<View
			viewInfo={{
				title: `${experience?.name ?? 'Experience'}`,
				icon: IconEnum.PLACE,
				breadCrumbs: [{ title: 'Experiences', route: '/experiences' }],
			}}
			loading={!experience}
			hideHeader
			key={`experience-view-${experienceId}`}
		>
			{!!experience && (
				<>
					<ViewPanel options={{ container: true }}>
						<div
							className={
								'flex justify-center items-center gap-4 h-full'
							}
						>
							<ExperienceDetailsInfo experience={experience} />

							{screenBreakpoint >= ScreenBreakpoint['3XL'] &&
								!authStore.havePermission(
									UserPermission.EDIT_TAG
								) && (
									<ViewPanel className={'max-w-96 h-full'}>
										<ExperienceDetailsTags
											experienceTags={experienceTags}
											experience={experience}
										/>
									</ViewPanel>
								)}
						</div>
					</ViewPanel>

					{(screenBreakpoint <= ScreenBreakpoint['2XL'] ||
						authStore.havePermission(UserPermission.EDIT_TAG)) && (
						<>
							<ViewPanel
								className={ClassString({
									dynamic: {
										'max-w-screen-sm':
											!authStore.havePermission(
												UserPermission.EDIT_TAG
											),
										'max-w-screen-lg':
											authStore.havePermission(
												UserPermission.EDIT_TAG
											),
									},
								})}
							>
								<ExperienceDetailsTags
									experienceTags={experienceTags}
									experience={experience}
								/>
							</ViewPanel>
						</>
					)}

					<LightdashDashboards dashboards={dashboards} />

					<ViewTabPanel
						tabs={[
							{
								key: 'ranking',
								title: 'Rankings',
								children: (
									<ExperienceDetailsRanking
										placeId={experience.place_id}
									/>
								),
							},
							{
								key: 'milestones',
								title: 'Ranking Milestones',
								children: (
									<ExperienceDetailsRankingTable
										placeId={experience.place_id}
										isHidden={
											currentRankingsTab !== 'milestones'
										}
									/>
								),
							},
						]}
						onTabChange={(tab) => {
							setCurrentRankingsTab(tab);
						}}
					/>

					<ViewTabPanel
						tabs={[
							{
								key: ExperienceCcuAndVisitsState.CCU,
								title: 'CCU',
							},
							{
								key: ExperienceCcuAndVisitsState.VISITS,
								title: 'Visits',
							},
							{
								key: ExperienceCcuAndVisitsState.BOTH,
								title: 'CCU & Visits',
							},
						]}
						onTabChange={(tab) => {
							setCCUChartState(tab);
						}}
					>
						<ExperienceCcuAndVisits
							place={experience}
							state={ccuChartState}
						/>
					</ViewTabPanel>

					<ViewTabPanel
						tabs={[
							{
								key: 'likes',
								title: 'Likes',
								children: (
									<PlaceStats
										places={[experience]}
										statType={PlaceStatType.LIKES}
										title={'Likes'}
										options={{
											selectedRange:
												experience.play_status !==
												ExperienceStatus.PLAYABLE
													? 7
													: 2,
										}}
									/>
								),
							},
							{
								key: 'like_percentage',
								title: 'Like Percentage',
								children: (
									<PlaceStats
										places={[experience]}
										statType={PlaceStatType.RATING}
										title={'Like Percentage'}
										options={{
											fill: true,
											singleGranularity:
												PlaceStatGranularity.DAY,
											yPercentage: true,
											lineColor: ChartColors()[2],
										}}
									/>
								),
							},
						]}
					/>
				</>
			)}
		</View>
	);
});

export default ExperienceDetails;
