import { observer } from 'mobx-react-lite';
import { ReactNode } from 'react';
import ClassString from '@utils/class-string.ts';
import scrollStore from '@store/scroll-store.ts';

interface ScrollProviderProps {
	children: ReactNode;
	center?: boolean;
}

const ScrollProvider = observer(function ScrollProvider(
	props: ScrollProviderProps
) {
	const handleScroll = (event: React.UIEvent<HTMLDivElement, UIEvent>) => {
		const target = event.target as HTMLDivElement;
		scrollStore.setScrollPosition(target.scrollTop);
	};

	return (
		<div
			className={ClassString({
				static: 'h-scroll-container overflow-y-scroll py-6',
				dynamic: {
					'flex items-center justify-center': props.center,
				},
			})}
			onScroll={handleScroll}
		>
			{props.children}
		</div>
	);
});

export default ScrollProvider;
