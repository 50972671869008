import { useEffect, useState } from 'react';

export default function useInitialScreenHeightChanged(): boolean {
	const [hasChanged, setHasChanged] = useState(true);
	const [previousHeight] = useState(window.innerHeight);

	useEffect(() => {
		const checkHeightChange = () => {
			const currentHeight = window.innerHeight;
			if (currentHeight === previousHeight) {
				clearInterval(intervalId);
				setHasChanged(false);
			}
		};

		// Set a 1-second interval to check for height change
		const intervalId = setInterval(checkHeightChange, 200);

		// Cleanup the interval on component unmount
		return () => clearInterval(intervalId);
	}, [previousHeight]);

	return hasChanged;
}
