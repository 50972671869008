import { observer } from 'mobx-react-lite';
import View from '@components/view/view.tsx';
import ViewPanel from '@components/view/view-panel.tsx';
import { IconEnum } from '@components/core/icon/icon-enum.ts';
import ExperienceTable from '@components/tables/experience-table.tsx';
import experienceStore from '@store/experience-store.ts';
import { useLoaderData } from 'react-router-dom';
import { CreatorDetailsLoaderReturn } from '@views/creator-details/creator-details-loader.ts';
import AverageCreatorCCU from '@components/data/average-creator-ccu/average-creator-ccu.tsx';
import ViewPanelSizeEnum from '@components/view/view-panel-size-enum.ts';
import QuickData from '@components/data/quick-data/quick-data.tsx';
import formatNumberWithSuffix from '@utils/format-number-with-suffix.ts';
import placeTagStore from '@store/place-tag-store.ts';
import CreatorThumbnail from '@components/data/creator-thumbnail/creator-thumbnail.tsx';
import NavigationButton from '@components/navigation/navigation-button/navigation-button.tsx';
import { CreatorThumbnailSize } from '@components/data/creator-thumbnail/creator-thumbnail-size.ts';
import Icon from '@components/core/icon/icon.tsx';
import { CreatorType } from '@/schemas/creator-schema.ts';
import { NavigationButtonEnum } from '@components/navigation/navigation-button/navigation-button-enum.ts';
import ViewExpansionPanel from '@components/view/view-expansion-panel.tsx';
import { useEffect, useState } from 'react';
import { CCUDataSegment } from '@store/place-stats-store.ts';
import ViewTabPanel from '@components/view/view-tab-panel.tsx';
import viewStore from '@store/view-store.ts';

const million = 1e6;

const CreatorDetails = observer(function CreatorDetails() {
	const { creator } = useLoaderData() as CreatorDetailsLoaderReturn;

	const [ccuChartState, setCCUChartState] = useState(CCUDataSegment.ACTIVE);

	const experiences = creator.place_ids
		? creator.place_ids
				.map((expId) => experienceStore.getSlimExperienceById(expId))
				.filter((exp) => !!exp)
		: [];

	const totalVisits = experiences.reduce(
		(a, b) => a + b.total_visits || 0,
		0
	);

	const externalUrl =
		creator.type === CreatorType.GROUP
			? `https://www.roblox.com/groups/${creator.id}`
			: `https://www.roblox.com/users/${creator.id}/profile/`;

	useEffect(() => {
		viewStore.loadingViewData = false;
	}, []);

	return (
		<View
			viewInfo={{
				title: creator.name,
				icon: IconEnum.GROUP,
				breadCrumbs: [{ title: 'Creators', route: '/creators' }],
			}}
			loading={
				!experienceStore.slimExperiences.length ||
				!placeTagStore.initialized
			}
			headerChildren={
				<div className={'w-full flex items-center justify-between'}>
					<div className={'flex items-center gap-3'}>
						<CreatorThumbnail
							creator={creator}
							size={CreatorThumbnailSize.SMALL}
							className={'h-14 w-14 rounded-md'}
						/>

						{creator.is_verified && (
							<div
								className={'flex items-center gap-2'}
								title={'Verified'}
							>
								<div
									className={'hidden sm:inline-block text-xl'}
								>
									{creator.name}
								</div>

								<Icon
									icon={IconEnum.CHECK_CIRCLE_OUTLINE}
									size={'1.5rem'}
								/>
							</div>
						)}

						{!creator.is_verified && (
							<div className={'hidden sm:inline-block text-xl'}>
								{creator.name}
							</div>
						)}
					</div>

					<NavigationButton
						path={externalUrl}
						title={'View on roblox.com'}
						direction={NavigationButtonEnum.EXTERNAL}
						external
						background
						outline
					/>
				</div>
			}
		>
			<ViewExpansionPanel
				title={`Experiences (${creator.place_ids?.length ?? 0})`}
				startExpanded
			>
				<ExperienceTable
					experiences={experiences}
					fillParent
					includeCreator={false}
				/>
			</ViewExpansionPanel>

			{!!experiences.length && (
				<>
					<ViewPanel
						options={{
							size: ViewPanelSizeEnum.THIRD,
							centerContent: true,
						}}
					>
						<QuickData
							data={new Intl.NumberFormat(
								navigator.language
							).format(
								experiences.reduce(
									(a, b) => a + b.players_online || 0,
									0
								)
							)}
							title={'Total Players Online'}
						/>
					</ViewPanel>

					<ViewPanel
						options={{
							size: ViewPanelSizeEnum.THIRD,
							centerContent: true,
						}}
					>
						<QuickData
							data={
								totalVisits >= million
									? `${formatNumberWithSuffix(totalVisits, 2)}+`
									: new Intl.NumberFormat(
											navigator.language
										).format(totalVisits)
							}
							title={'Total Visits'}
						/>
					</ViewPanel>

					<ViewPanel
						options={{
							size: ViewPanelSizeEnum.THIRD,
							centerContent: true,
						}}
					>
						<QuickData
							data={`${(
								(experiences
									.filter((exp) => !!exp?.like_percentage)
									.map((exp) => exp?.like_percentage)
									.reduce((a, b) => a + b, 0) /
									experiences.filter(
										(exp) => !!exp?.like_percentage
									).length) *
								100
							).toFixed(2)}%`}
							title={'Average Like Percentage'}
						/>
					</ViewPanel>

					<ViewTabPanel
						tabs={[
							{
								key: CCUDataSegment.ACTIVE,
								title: 'Active',
								tooltip:
									'Only include experiences with active players at the specific timestamp.',
							},
							{
								key: CCUDataSegment.TOP_FIVE,
								title: 'Top Five',
								tooltip: (
									<div>
										{
											'Only include the top 5 experiences of the creator in terms of current player count.'
										}
										<ul className="pl-5 mt-1 list-disc">
											{experiences
												.sort(
													(a, b) =>
														b.players_online -
														a.players_online
												)
												.slice(0, 5)
												.map((exp) => {
													return (
														<li key={exp.place_id}>
															{exp.canonical_name}
														</li>
													);
												})}
										</ul>
									</div>
								),
							},
							{
								key: CCUDataSegment.ALL,
								title: 'All',
								tooltip:
									'Include all experiences that was created at or after each specific timestamp.',
							},
						]}
						onTabChange={(tab) => {
							setCCUChartState(tab);
						}}
					>
						<AverageCreatorCCU
							creator={creator}
							creatorExperiences={experiences}
							state={ccuChartState}
						/>
					</ViewTabPanel>
				</>
			)}
		</View>
	);
});

export default CreatorDetails;
