import { observer } from 'mobx-react-lite';
import View from '@components/view/view.tsx';
import ViewPanel from '@components/view/view-panel.tsx';
import experienceStore from '@store/experience-store.ts';
import { useState } from 'react';
import { Experience, SlimExperience } from '@/schemas/experience-schema.ts';
import { IconEnum } from '@components/core/icon/icon-enum.ts';
import Button from '@components/core/button/button.tsx';
import CopyToClipboard from '@utils/copy-to-clipboard.ts';
import toastStore from '@store/toast-store.ts';
import { ToastType } from '@components/service/toast/toast-enums.ts';
import { PlaceTag } from '@/schemas/plage-tag-schema.ts';
import TagSelector from '@components/core/tag-selector/tag-selector.tsx';
import ExperienceVsTagCcu from '@components/data/experience-vs-tag-ccu/experience-vs-tag-cuu.tsx';
import ChartColors from '@components/core/chart/chart-colors.ts';
import DataComparisonRow from '@components/data/data-comparison-row/data-comparison-row.tsx';
import formatNumberWithSuffix from '@utils/format-number-with-suffix.ts';
import placeTagStore from '@store/place-tag-store.ts';
import { CCUDataSegment } from '@store/place-stats-store.ts';
import ViewTabPanel from '@components/view/view-tab-panel.tsx';
import MultipleExperienceSelector from '@components/core/multiple-experience-selector/multiple-experience-selector.tsx';
import LightdashDashboards from '@components/data/lightdash-dashboards/lightdash-dashboards.tsx';
import DashboardType from '@components/data/lightdash-dashboards/dashboard-type.ts';

const averagePlayerCount = (experiences: Experience[]) => {
	return +(
		experiences
			.map((exp) => exp.players_online)
			.reduce((acc, curr) => acc + curr, 0) / experiences.length
	).toFixed(0);
};

const averageLikeRatio = (experiences: Experience[]) => {
	return (
		experiences
			.map((exp) => exp.like_percentage)
			.reduce((acc, curr) => acc + curr, 0) / experiences.length
	);
};

const averageVisits = (experiences: Experience[]) => {
	return +(
		experiences
			.map((exp) => exp.total_visits)
			.reduce((acc, curr) => acc + curr, 0) / experiences.length
	).toFixed(0);
};

const ExperienceTagComparison = observer(function ExperienceComparison() {
	const [experiences, setExperiences] = useState<Experience[]>([]);

	const [tag, setTag] = useState<PlaceTag | undefined>(undefined);

	const [ccuChartState, setCCUChartState] = useState(CCUDataSegment.ACTIVE);

	const experiencesSelected = !!experiences.length && !!tag;

	const loading =
		!experienceStore.slimExperiences.length || !placeTagStore.placeTags;

	const tagExperiences: SlimExperience[] = tag
		? tag.place_ids
				.map((id) => experienceStore.getSlimExperienceById(id))
				.filter((exp) => !!exp)
		: [];

	const averageExpPlayerCount = averagePlayerCount(experiences);
	const averageTagPlayerCount = averagePlayerCount(tagExperiences);

	const averageExpLikeRatio = averageLikeRatio(experiences);
	const averageTagLikeRatio = averageLikeRatio(tagExperiences);

	const averageExpVisits = averageVisits(experiences);
	const averageTagVisits = averageVisits(tagExperiences);

	const copyLink = () => {
		CopyToClipboard(window.location.href);
		toastStore.emit(
			'Comparison link has been copied to your clipboard.',
			ToastType.INFO
		);
	};

	return (
		<View
			viewInfo={{
				title: 'Compare Tag and Experiences',
				icon: IconEnum.COMPARE,
				breadCrumbs: [{ title: 'Compare' }],
			}}
			hideHeader
		>
			<ViewPanel loading={loading}>
				{!loading && (
					<div className={'w-full flex flex-wrap justify-evenly'}>
						<div
							className={
								'p-6 flex flex-col justify-center items-center max-w-96 lg:h-72 m-2'
							}
						>
							<div>
								Select a tag and up to six experiences to
								display comparative stats.
							</div>

							<div className={'mt-2'}>
								You can also bookmark a comparison to quickly
								return to it or share the comparison with
								someone else.
							</div>

							<div className={'mt-6'}>
								<Button
									title={'Share'}
									icon={{
										icon: IconEnum.SHARE,
										placement: 'left',
									}}
									onClick={copyLink}
								/>
							</div>
						</div>

						<TagSelector
							tag={tag}
							setTag={setTag}
							index={1}
							color={ChartColors()[0]}
						/>

						<MultipleExperienceSelector
							experiences={experiences}
							onAddExperience={(exp) => {
								setExperiences([...experiences, ...exp]);
							}}
							onRemoveExperience={(experience) => {
								setExperiences(
									experiences.filter(
										(exp) =>
											exp.place_id !== experience.place_id
									)
								);
							}}
							colors={[ChartColors()[1]]}
						/>
					</div>
				)}
			</ViewPanel>

			{experiencesSelected && (
				<>
					{experiences.length === 1 && (
						<LightdashDashboards
							dashboards={[
								{
									type: DashboardType.COMPARE_EXPERIENCE_AND_TAG,
									experience: experiences[0],
									tag,
								},
							]}
						/>
					)}

					<ViewPanel>
						<div className={'flex flex-col justify-center'}>
							<DataComparisonRow
								label={'Name'}
								dataLeft={tag?.name}
								dataRight={
									experiences.length === 1
										? experiences[0].canonical_name
										: 'Experiences'
								}
								borderBottom
							/>

							<DataComparisonRow
								label={'Player Count'}
								dataLeft={`Avg. ${averageTagPlayerCount}`}
								dataRight={`${experiences.length > 1 ? 'Avg. ' : ''}${averageExpPlayerCount}`}
								dataDifference={{
									direction:
										averageTagPlayerCount >
										averageExpPlayerCount
											? 'left'
											: 'right',
									data:
										averageTagPlayerCount >
										averageExpPlayerCount
											? `${averageTagPlayerCount - averageExpPlayerCount}`
											: `${averageExpPlayerCount - averageTagPlayerCount}`,
								}}
								borderBottom
							/>

							<DataComparisonRow
								label={'Like %'}
								dataLeft={`Avg. ${(averageTagLikeRatio * 100).toFixed(2)}%`}
								dataRight={`${experiences.length > 1 ? 'Avg. ' : ''}${(averageExpLikeRatio * 100).toFixed(2)}%`}
								dataDifference={{
									direction:
										averageTagLikeRatio >
										averageExpLikeRatio
											? 'left'
											: 'right',
									data:
										averageTagLikeRatio >
										averageExpLikeRatio
											? `${((averageTagLikeRatio - averageExpLikeRatio) * 100).toFixed(2)}%`
											: `${((averageExpLikeRatio - averageTagLikeRatio) * 100).toFixed(2)}%`,
								}}
								borderBottom
							/>

							<DataComparisonRow
								label={'Visits'}
								dataLeft={`Avg. ${formatNumberWithSuffix(
									averageTagVisits
								)}`}
								dataRight={`${experiences.length > 1 ? 'Avg. ' : ''}${formatNumberWithSuffix(
									averageExpVisits
								)}`}
								dataDifference={{
									direction:
										averageTagVisits > averageExpVisits
											? 'left'
											: 'right',
									data:
										averageTagVisits > averageExpVisits
											? formatNumberWithSuffix(
													averageTagVisits -
														averageExpVisits
												)
											: formatNumberWithSuffix(
													averageExpVisits -
														averageTagVisits
												),
								}}
							/>
						</div>
					</ViewPanel>

					<ViewTabPanel
						tabs={[
							{
								key: CCUDataSegment.ACTIVE,
								title: 'Tag Active',
								tooltip:
									'Only include the tags experiences with active players at the specific timestamp.',
							},
							{
								key: CCUDataSegment.TOP_FIVE,
								title: 'Tag Top Five',
								tooltip: (
									<div>
										{
											'Only include the top 5 experiences of the tag in terms of current player count.'
										}
										<ul className="pl-5 mt-1 list-disc">
											{tagExperiences
												.sort(
													(a, b) =>
														b.players_online -
														a.players_online
												)
												.slice(0, 5)
												.map((exp) => {
													return (
														<li key={exp.place_id}>
															{exp.canonical_name}
														</li>
													);
												})}
										</ul>
									</div>
								),
							},
							{
								key: CCUDataSegment.ALL,
								title: 'Tag All',
								tooltip:
									'Include all experiences of the tag that was created at or after each specific timestamp.',
							},
						]}
						onTabChange={(tab) => {
							setCCUChartState(tab);
						}}
					>
						<ExperienceVsTagCcu
							experiences={experiences}
							tag={tag}
							tagExperiences={tagExperiences}
							state={ccuChartState}
							title={'Experiences vs Average Tag CCU'}
						/>
					</ViewTabPanel>
				</>
			)}
		</View>
	);
});

export default ExperienceTagComparison;
