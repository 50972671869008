import { observer } from 'mobx-react-lite';
import { useRef, useState } from 'react';
import Button from '@components/core/button/button.tsx';
import { BUTTON_STYLE } from '@components/core/button/button-style-enum.ts';
import Form from '@components/core/form/form.tsx';
import PlaceExpansionInput from '@components/data/place-expansion-input/place-expansion-input.tsx';
import { Experience } from '@/schemas/experience-schema.ts';
import modalStore from '@store/modal-store.ts';

interface ExperienceTagDetailsBulkAddProps {
	tagName: string;
	submit: (experienceId: number) => unknown;
}

const ExperienceTagDetailsBulk = observer(function ExperienceTagDetailsBulkAdd(
	props: ExperienceTagDetailsBulkAddProps
) {
	const [searchTerm, setSearchTerm] = useState('');
	const [experience, setExperience] = useState<Experience | undefined>();
	const submitButtonRef = useRef<HTMLButtonElement>(null);
	const validExperienceId = searchTerm.length && /^\d+$/.test(searchTerm);

	return (
		<Form
			className={'flex items-center flex-col gap-4 w-full'}
			onSubmit={() => {
				props.submit(experience?.place_id ?? +searchTerm);
				modalStore.close();
			}}
		>
			<div
				className={'mb-4'}
			>{`Add experience to "${props.tagName}".`}</div>

			<PlaceExpansionInput
				searchTerm={searchTerm}
				setSearchTerm={setSearchTerm}
				place={experience}
				setPlace={setExperience}
				elementToFocus={submitButtonRef}
			/>

			<div className={'w-full flex justify-evenly mt-2'}>
				<Button
					title={'Cancel'}
					onClick={() => {
						modalStore.close();
					}}
				/>

				<Button
					title={`Add`}
					buttonStyle={BUTTON_STYLE.PRIMARY}
					disabled={!experience && !validExperienceId}
					innerRef={submitButtonRef}
				/>
			</div>
		</Form>
	);
});

export default ExperienceTagDetailsBulk;
