import { observer } from 'mobx-react-lite';
import NavigationButton from '@components/navigation/navigation-button/navigation-button.tsx';
import { NavigationButtonEnum } from '@components/navigation/navigation-button/navigation-button-enum.ts';
import TGSTable, { CustomColDef } from '@components/core/table/table.tsx';
import { CustomCellRendererProps } from 'ag-grid-react';
import Icon from '@components/core/icon/icon.tsx';
import { IconEnum } from '@components/core/icon/icon-enum.ts';
import { Creator, CreatorType } from '@/schemas/creator-schema.ts';
import { ValueFormatterParams } from 'ag-grid-community';
import CreatorThumbnail from '@components/data/creator-thumbnail/creator-thumbnail.tsx';
import { CreatorThumbnailSize } from '@components/data/creator-thumbnail/creator-thumbnail-size.ts';

interface CreatorTableProps {
	creators: Creator[];
}

const columnDefs: CustomColDef<Creator>[] = [
	{
		minWidth: 55,
		maxWidth: 55,
		cellRenderer: (params: CustomCellRendererProps) => {
			const creator = params.node.data as Creator;
			return (
				<div
					className={'w-full h-full flex justify-center items-center'}
				>
					<CreatorThumbnail
						creator={creator}
						size={CreatorThumbnailSize.VERY_VERY_SMALL}
					/>
				</div>
			);
		},
		cellStyle: {
			display: 'flex',
			alignItems: 'center',
			paddingRight: '0',
		},
		resizable: false,
		sortable: false,
		context: {
			priority: 1,
		},
	},
	{
		headerName: 'Name',
		field: 'name',
		resizable: false,
		cellRenderer: (params: CustomCellRendererProps) => {
			const creator = params.node.data as Creator;
			return (
				<NavigationButton
					path={`/creators/${creator.id}`}
					direction={NavigationButtonEnum.FORWARD}
					title={creator.name}
				/>
			);
		},
		cellStyle: {
			display: 'flex',
			alignItems: 'center',
			paddingLeft: '25px',
			paddingRight: '0',
		},
		minWidth: 300,
		context: {
			priority: 1,
		},
		flex: 1,
	},
	{
		headerName: 'Experiences',
		field: 'place_ids',
		resizable: false,
		valueFormatter: (params: ValueFormatterParams<Creator, number[]>) => {
			return params.value ? params.value.length.toString() : '0';
		},
		comparator: (a: number[], b: number[]) => {
			if (a.length === b.length) {
				return 0;
			}
			return a.length > b.length ? 1 : -1;
		},
		sort: 'desc',
		cellStyle: {
			display: 'flex',
			alignItems: 'center',
		},
		minWidth: 150,
		maxWidth: 150,
		context: {
			priority: 2,
		},
	},
	{
		headerName: 'Verified',
		field: 'is_verified',
		cellRenderer: (params: CustomCellRendererProps) => {
			const creator = params.node.data as Creator;
			return (
				<>
					{creator.is_verified && (
						<Icon
							icon={IconEnum.CHECK_CIRCLE_OUTLINE}
							size={'1.75rem'}
						/>
					)}
				</>
			);
		},
		resizable: false,
		cellStyle: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
		},
		headerClass: 'text-center',
		minWidth: 100,
		maxWidth: 100,
		context: {
			priority: 2,
		},
	},
	{
		headerName: 'Type',
		field: 'type',
		cellRenderer: (params: CustomCellRendererProps) => {
			const creator = params.node.data as Creator;
			return (
				<div className={'flex items-center'}>
					{creator.type}

					<Icon
						icon={
							creator.type === CreatorType.USER
								? IconEnum.USER
								: IconEnum.GROUP
						}
						size={'1.5rem'}
						className={'ml-2'}
					/>
				</div>
			);
		},
		resizable: false,
		cellStyle: {
			display: 'flex',
			alignItems: 'center',
		},
		minWidth: 150,
		maxWidth: 150,
		context: {
			priority: 2,
		},
	},
];

const CreatorTable = observer(function CreatorTable(props: CreatorTableProps) {
	return (
		<TGSTable
			columnDefs={columnDefs}
			rowData={props.creators}
			tableName={'experience-creator-table'}
			rowHeight={50}
		/>
	);
});

export default CreatorTable;
