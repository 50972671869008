const billion = 1e9;
const million = 1e6;
const thousand = 1e3;

function truncNumber(num: number, fractionDigits: number) {
	return (
		Math.trunc(num * Math.pow(10, fractionDigits)) /
		Math.pow(10, fractionDigits)
	);
}

function formatNumberWithSuffix(num: number, decimals = 1): string {
	if (num >= billion) {
		return `${truncNumber(num / billion, decimals)}B`;
	} else if (num >= million) {
		return `${truncNumber(num / million, decimals)}M`;
	} else if (num >= thousand) {
		return `${truncNumber(num / thousand, decimals)}K`;
	} else {
		return num.toString();
	}
}

export default formatNumberWithSuffix;
