import CopyToClipboard from '@utils/copy-to-clipboard.ts';
import toastStore from '@store/toast-store.ts';
import { ToastType } from '@components/service/toast/toast-enums.ts';
import { Experience } from '@/schemas/experience-schema.ts';
import { EventCredential } from '@/schemas/event-credential-schema.ts';
import hashQueryParamService from '@services/hash-query-param-service.ts';
import { HashParamMap } from '@/enums/hash-params.ts';

class CopyService {
	experienceId(experience: Experience) {
		CopyToClipboard(experience.place_id.toString());
		toastStore.emit(
			'Experience ID has been copied to your clipboard.',
			ToastType.INFO
		);
	}

	experienceUniverseId(experience: Experience) {
		CopyToClipboard(experience.universe_id.toString());
		toastStore.emit(
			'Universe ID has been copied to your clipboard.',
			ToastType.INFO
		);
	}

	gameKey(eventCredential: EventCredential) {
		CopyToClipboard(eventCredential.game_key);
		toastStore.emit(
			'Game key has been copied to your clipboard.',
			ToastType.INFO
		);
	}

	secretKey(eventCredential: EventCredential) {
		CopyToClipboard(eventCredential.secret_key);
		toastStore.emit(
			'Secret key has been copied to your clipboard.',
			ToastType.INFO
		);
	}

	anchorLink(anchorId: string, extraParams?: HashParamMap) {
		const hashParams = hashQueryParamService.create({
			anchor: anchorId,
			...extraParams,
		});

		const url = window.location.href.split('#')[0];
		CopyToClipboard(`${url}#${hashParams}`);
		toastStore.emit(
			'Anchor link has been copied to your clipboard.',
			ToastType.INFO
		);
	}
}

const copyService = new CopyService();
export default copyService;
