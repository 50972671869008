import TGSTable, { CustomColDef } from '@components/core/table/table.tsx';
import { Experience } from '@/schemas/experience-schema.ts';
import { observer } from 'mobx-react-lite';
import { ValueFormatterParams } from 'ag-grid-community';
import formatNumberWithSuffix from '@utils/format-number-with-suffix.ts';
import { CustomCellRendererProps } from 'ag-grid-react';
import getCycledItem from '@utils/get-cycled-item.ts';
import BrandColors from '@styles/_brand_colors.ts';
import useScreenBreakpoint from '@hooks/use-screen-breakpoint.tsx';
import { ScreenBreakpoint } from '@/enums/screen-breakpoints.ts';

interface ExperienceComparisonMultipleExperiencesTableProps {
	experiences: Experience[];
	colors: BrandColors[];
}

const numberFormatter = new Intl.NumberFormat(navigator.language);

const ExperienceComparisonMultipleExperiencesTable = observer(
	function ExperienceComparisonMultipleExperiencesTable(
		props: ExperienceComparisonMultipleExperiencesTableProps
	) {
		const screenBreakpoint = useScreenBreakpoint();

		const columnDefs: CustomColDef<Experience>[] = [
			...(screenBreakpoint > ScreenBreakpoint.SM
				? [
						{
							minWidth: 60,
							maxWidth: 60,
							cellRenderer: (params: CustomCellRendererProps) => {
								const experience = params.node
									.data as Experience;
								const index = props.experiences.findIndex(
									(exp) =>
										exp.place_id === experience.place_id
								);
								return (
									<div
										className={
											'h-full flex items-center justify-center'
										}
									>
										<div
											className={'w-8 h-8'}
											style={{
												backgroundColor: getCycledItem(
													index,
													props.colors
												),
											}}
										></div>
									</div>
								);
							},
							resizable: false,
							sortable: false,
							context: {
								priority: 1,
							},
						},
					]
				: []),
			{
				headerName: '',
				field: 'name',
				cellRenderer: (params: CustomCellRendererProps) => {
					const experience = params.node.data as Experience;
					const index = props.experiences.findIndex(
						(exp) => exp.place_id === experience.place_id
					);
					return (
						<div
							className={
								'underline sm:no-underline decoration-4 flex items-center h-full'
							}
							style={{
								textDecorationColor: getCycledItem(
									index,
									props.colors
								),
							}}
						>
							<div className={'truncate'}>{experience.name}</div>
						</div>
					);
				},
				resizable: false,
				context: {
					priority: 1,
				},
				flex: 3,
			},
			{
				headerName: 'Player Count',
				field: 'players_online',
				resizable: false,
				valueFormatter: (
					params: ValueFormatterParams<Experience, number>
				) => {
					const value = params.value ?? 0;
					return numberFormatter.format(value);
				},
				context: {
					priority: 1,
				},
				flex: 1,
				minWidth: 100,
			},
			{
				headerName: 'Like %',
				field: 'like_percentage',
				resizable: false,
				valueFormatter: (
					params: ValueFormatterParams<Experience, number>
				) => {
					const value = params.value ?? 0;
					return `${(value * 100).toFixed(2)}%`;
				},
				context: {
					priority: 1,
				},
				flex: 1,
				minWidth: 85,
			},
			{
				headerName: 'Visits',
				field: 'total_visits',
				resizable: false,
				valueFormatter: (
					params: ValueFormatterParams<Experience, number>
				) => {
					const value = params.value ?? 0;
					return formatNumberWithSuffix(value);
				},
				context: {
					priority: 1,
				},
				flex: 1,
				minWidth: 85,
			},
		];

		return (
			<TGSTable
				columnDefs={columnDefs}
				rowData={props.experiences}
				tableName={'place-table'}
				rowHeight={50}
				disablePagination
			/>
		);
	}
);

export default ExperienceComparisonMultipleExperiencesTable;
