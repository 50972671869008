const getCycledItem = <T>(index: number, array: T[]): T => {
	const arrayCount = array.length;

	// Adjust the index to cycle over the colors array
	const adjustedIndex = ((index % arrayCount) + arrayCount) % arrayCount;

	return array[adjustedIndex];
};

export default getCycledItem;
