import { useState, useEffect } from 'react';
import { ScreenBreakpoint } from '@/enums/screen-breakpoints.ts';

const getCurrentBreakpoint = (width: number) => {
	if (width <= ScreenBreakpoint.SM) return ScreenBreakpoint.SM;
	if (width <= ScreenBreakpoint.MD) return ScreenBreakpoint.MD;
	if (width <= ScreenBreakpoint.LG) return ScreenBreakpoint.LG;
	if (width <= ScreenBreakpoint.XL) return ScreenBreakpoint.XL;
	if (width <= ScreenBreakpoint['2XL']) return ScreenBreakpoint['2XL'];
	if (width <= ScreenBreakpoint['3XL']) return ScreenBreakpoint['3XL'];
	return ScreenBreakpoint['4XL'];
};

const useScreenBreakpoint = () => {
	const [breakpoint, setBreakpoint] = useState(() =>
		getCurrentBreakpoint(window.innerWidth)
	);

	useEffect(() => {
		const handleResize = () => {
			const currentBreakpoint = getCurrentBreakpoint(window.innerWidth);
			setBreakpoint(currentBreakpoint);
		};

		// Add resize event listener
		window.addEventListener('resize', handleResize);

		// Cleanup on unmount
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	return breakpoint;
};

export default useScreenBreakpoint;
