import { observer } from 'mobx-react-lite';
import { IconEnum } from '@components/core/icon/icon-enum.ts';
import { NavLink } from 'react-router-dom';
import Icon from '@components/core/icon/icon.tsx';
import ClassString from '@utils/class-string.ts';
import { useState } from 'react';
import navSidebarStore from '@store/nav-sidebar-store.ts';

interface NavSidebarLinkProps {
	title: string;
	route: string;
	icon?: NavSidebarLinkIcon;
	className?: string;
	whiteSpaceWrap?: boolean;
}

export interface NavSidebarLinkIcon {
	icon: IconEnum;
	size: string;
}

const isExperienceRouteActive = () => {
	return !window.location.href.includes('/experiences/compare');
};

const NavSidebarLink = observer(function NavSidebarLink(
	props: NavSidebarLinkProps
) {
	const [routeActive, setRouteActive] = useState(false);

	return (
		<div
			className={ClassString({
				static: 'rounded-md hover:bg-blue-500 my-1 w-full',
				dynamic: {
					'bg-blue-500': routeActive,
				},
				custom: props.className,
			})}
		>
			<NavLink
				to={props.route}
				draggable="false"
				className={({ isActive }) => {
					if (props.route === '/experiences') {
						const experienceRouteActive =
							isExperienceRouteActive() && isActive;

						if (experienceRouteActive !== routeActive) {
							setTimeout(() => {
								setRouteActive(experienceRouteActive);
							});
						}
					} else {
						if (isActive !== routeActive) {
							setTimeout(() => {
								setRouteActive(isActive);
							});
						}
					}

					return 'no-underline';
				}}
				onClick={() => {
					navSidebarStore.setOpen(false);
				}}
			>
				<div
					className={ClassString({
						static: 'flex items-center text-white text-lg px-4 py-2',
						dynamic: {
							'lg:justify-center': navSidebarStore.minimal,
						},
					})}
				>
					{props.icon && (
						<div
							className={ClassString({
								static: 'mr-2 flex items-center',
								dynamic: {
									'lg:mr-0': navSidebarStore.minimal,
								},
							})}
						>
							<Icon
								icon={props.icon.icon}
								size={props.icon.size}
							/>
						</div>
					)}
					<div
						className={ClassString({
							static: 'whitespace-nowrap',
							dynamic: {
								'lg:hidden': navSidebarStore.minimal,
								'whitespace-normal': props.whiteSpaceWrap,
							},
						})}
					>
						{props.title}
					</div>
				</div>
			</NavLink>
		</div>
	);
});

export default NavSidebarLink;
