import { observer } from 'mobx-react-lite';
import Highcharts from 'highcharts';
import Chart from '@components/core/chart/chart.tsx';
import { useCallback, useEffect, useState } from 'react';
import placeStatsStore, {
	isValidGranularity,
	PlaceStatGranularity,
	PlaceStatType,
} from '@store/place-stats-store.ts';
import LoadingIndicator from '@components/core/loading-indicator/loading-indicator.tsx';
import {
	ExperienceStatus,
	FullExperience,
} from '@/schemas/experience-schema.ts';
import hexToRGBA from '@utils/hex-to-rgba.ts';
import ChartColors from '@components/core/chart/chart-colors.ts';
import themeStore from '@store/theme-store.ts';
import ThemeStore from '@store/theme-store.ts';
import BrandColors from '@styles/_brand_colors.ts';
import thumbnailStore from '@store/thumbnail-store.ts';
import { PlaceThumbnailSize } from '@components/data/place-thumbnail/place-thumbnail-constants.ts';
import { ThumbnailType } from '@/schemas/thumbnail-schema.ts';
import { PlaceBannerSize } from '@components/data/place-banner/place-banner-constants.ts';
import ClassString from '@utils/class-string.ts';
import { ExperienceCcuAndVisitsState } from '@components/data/experience-ccu-and-visits/experience-ccu-and-visits-state.ts';
import GranularitySelector from '@components/data/granularity-selector/granularity-selector.tsx';
import modalStore from '@store/modal-store.ts';
import hashQueryParamService from '@services/hash-query-param-service.ts';
import getGraphId from '@utils/get-graph-id.ts';
import SlideToggle from '@components/core/slide-toggle/slide-toggle.tsx';
import { IconEnum } from '@components/core/icon/icon-enum.ts';

interface ExperienceCcuAndVisitsProps {
	place: FullExperience;
	state: ExperienceCcuAndVisitsState;
}

const ChartTitles: Record<ExperienceCcuAndVisitsState, string> = {
	[ExperienceCcuAndVisitsState.CCU]: 'Concurrent Users',
	[ExperienceCcuAndVisitsState.VISITS]: 'New Visits',
	[ExperienceCcuAndVisitsState.BOTH]: 'Concurrent Users and New Visits',
};

const getDefaultGranularity = (title: string) => {
	const hashParams = hashQueryParamService.get();
	if (
		hashParams.granularity &&
		hashParams.anchor === getGraphId(title) &&
		isValidGranularity(hashParams.granularity)
	) {
		return hashParams.granularity as PlaceStatGranularity;
	}

	return PlaceStatGranularity.DAY;
};

const ExperienceCcuAndVisits = observer(function ExperienceCcuAndVisits(
	props: ExperienceCcuAndVisitsProps
) {
	const [granularity, setGranularity] = useState(
		getDefaultGranularity(ChartTitles[props.state])
	);
	const [loading, setLoading] = useState(true);

	const [showThumbnails, setShowThumbnails] = useState(true);
	const [showBanners, setShowBanners] = useState(true);

	const thumbnails = thumbnailStore.getAllThumbnails(props.place)
		? thumbnailStore.getAllThumbnails(props.place).filter((thumbnail) => {
				if (showThumbnails && showBanners) {
					return true;
				}

				if (!showThumbnails && thumbnail.type === ThumbnailType.ICON) {
					return false;
				}

				if (!showBanners && thumbnail.type === ThumbnailType.BANNER) {
					return false;
				}

				return true;
			})
		: [];

	const series: {
		name: string;
		color: string;
		data: number[][] | undefined;
	}[] = [];

	if (
		props.state === ExperienceCcuAndVisitsState.VISITS ||
		props.state === ExperienceCcuAndVisitsState.BOTH
	) {
		series.push({
			name: 'New Visits',
			color: ChartColors()[1],
			data: placeStatsStore.placeStat(
				props.place.place_id,
				PlaceStatType.VISITS,
				granularity
			),
		});
	}

	if (
		props.state === ExperienceCcuAndVisitsState.CCU ||
		props.state === ExperienceCcuAndVisitsState.BOTH
	) {
		series.push({
			name: 'Concurrent Users',
			color: ChartColors()[0],
			data: placeStatsStore.placeStat(
				props.place.place_id,
				PlaceStatType.CCUS,
				granularity
			),
		});
	}

	const noData = !loading && !series.some((series) => !!series.data?.length);

	const getData = useCallback(
		async (tf: PlaceStatGranularity) => {
			await Promise.all([
				placeStatsStore.getPlaceStats(
					[props.place.place_id],
					PlaceStatType.VISITS,
					tf
				),
				placeStatsStore.getPlaceStats(
					[props.place.place_id],
					PlaceStatType.CCUS,
					tf
				),
			]);

			setLoading(false);
		},
		[props.place]
	);

	const yMin = undefined;
	const yMax = undefined;

	let xMin = 0;
	let xMax = 0;
	series.forEach((pd) => {
		if (pd.data) {
			pd.data.forEach((d) => {
				const date = d[0];

				if (xMin === 0) {
					xMin = date;
				}

				if (xMax === 0) {
					xMax = date;
				}

				if (date < xMin) {
					xMin = date;
				}

				if (date > xMax) {
					xMax = date;
				}
			});
		}
	});

	const options: Highcharts.Options = {
		title: {
			text: ChartTitles[props.state],
		},
		series: series.map((series) => {
			return {
				name: series.name,
				type: 'area',
				showInLegend: false,
				data: series.data ?? [],
				marker: {
					enabled: false,
				},
				lineWidth: 3,
				lineColor: series.color,
				fillColor: hexToRGBA(series.color, 0.6),
			};
		}),
		annotations: [
			{
				draggable: '',
				labels: [
					...thumbnails.map((thumbnail) => {
						const currentYear = new Date().getFullYear();
						const cutoffDate = new Date(`${currentYear}-10-03`);
						const thumbnailDate = new Date(thumbnail.time);

						const hashPrefix =
							thumbnailDate >= cutoffDate ? '180DAY-' : '';

						const imgUrl = `https://tr.rbxcdn.com/${hashPrefix}${thumbnail.hash}/${thumbnail.type === ThumbnailType.ICON ? PlaceThumbnailSize.SMALL : PlaceBannerSize.SMALL}/Image/Webp/noFilter`;

						return {
							useHTML: true,
							allowOverlap: true,
							point: {
								xAxis: 0,
								yAxis: 400,
								x: new Date(thumbnail.time).getTime(),
								y: 0,
							},
							y:
								thumbnail.type === ThumbnailType.ICON ||
								!showThumbnails
									? -16
									: 60,
							backgroundColor: 'transparent',
							borderWidth: 0,
							text: `<div id="${hashPrefix}${thumbnail.hash}-${thumbnail.type}" class="group flex flex-col justify-center items-center gap-2 dark:text-white bg-white dark:bg-gray-525 rounded-sm p-1 outline outline-1 outline-gray-300 dark:outline-gray-500 cursor-pointer hover:absolute hover:z-in-front">
										<div class="${ClassString({
											static: 'flex group-hover:hidden flex-col justify-center items-center gap-2 pointer-events-none',
											dynamic: {
												'min-h-8 min-w-8':
													thumbnail.type ===
													ThumbnailType.ICON,
												'min-h-6 min-w-9':
													thumbnail.type ===
													ThumbnailType.BANNER,
											},
										})}">
							                  <img src="${imgUrl}" alt="" class="${ClassString({
													static: 'pointer-events-none',
													dynamic: {
														'h-8 w-8':
															thumbnail.type ===
															ThumbnailType.ICON,
														'h-5 w-9':
															thumbnail.type ===
															ThumbnailType.BANNER,
													},
												})}">
										</div>
										
										<div class="hidden group-hover:flex flex-col justify-center items-center gap-2 pointer-events-none">
							                <img src="${imgUrl}" alt="" class="${ClassString({
												static: 'pointer-events-none',
												dynamic: {
													'h-16 w-16':
														thumbnail.type ===
														ThumbnailType.ICON,
													'h-11 w-16':
														thumbnail.type ===
														ThumbnailType.BANNER,
												},
											})}">
							                <div>
							                	${thumbnail.type === ThumbnailType.ICON ? 'Thumbnail' : 'Banner'}
											</div>
											<div>
							                	${new Date(thumbnail.time).toLocaleDateString()}
							                </div>
							                <div>
							                	${new Date(thumbnail.time).toLocaleTimeString()}
							                </div>
										</div>
					               </div>`,
						};
					}),
				],
				shapes: [
					...thumbnails.map((thumbnail) => {
						return {
							type: 'path',
							points: [
								{
									xAxis: 0,
									yAxis: 400,
									x: new Date(thumbnail.time).getTime(),
									y: 0,
								},
								{
									xAxis: 0,
									yAxis: 0,
									x: new Date(thumbnail.time).getTime(),
									y: 0,
								},
							],
							strokeWidth: 1, // Line thickness
							stroke: ThemeStore.lightTheme
								? BrandColors.gray600
								: BrandColors.white, // Line color (semi-transparent black)
						};
					}),
				],
				events: {
					click: (clickEvent) => {
						if (clickEvent) {
							const label = clickEvent.target as HTMLDivElement;
							const splitId = label.id.split('-');
							const prefix =
								splitId.length < 3
									? ''
									: `${label.id.split('-')[0]}-`;
							const hash =
								splitId.length < 3 ? splitId[0] : splitId[1];
							const type =
								splitId.length < 3 ? splitId[1] : splitId[2];
							const typeEnum =
								type === 'Icon'
									? ThumbnailType.ICON
									: ThumbnailType.BANNER;
							const imgUrl = `https://tr.rbxcdn.com/${prefix}${hash}/${typeEnum === ThumbnailType.ICON ? PlaceThumbnailSize.VERY_LARGE : PlaceBannerSize.VERY_LARGE}/Image/Webp/noFilter`;

							modalStore.open(<img src={imgUrl} alt={''} />);
						}
					},
				},
			},
		],
		chart: {
			marginLeft: 45,
		},
		xAxis: {
			type: 'datetime',
			title: {
				y: 5,
				style: {
					color: themeStore.lightTheme
						? BrandColors.gray600
						: BrandColors.white,
					fontStyle: 'italic',
				},
			},
			min: xMin,
			max: xMax,
		},
		yAxis: {
			title: {
				enabled: false,
			} as Highcharts.YAxisOptions,
			labels: {
				x: -10,
				y: 3,
				reserveSpace: false,
				style: {
					whiteSpace: 'nowrap',
				},
			},
			min: yMin,
			max: yMax,
		},
		plotOptions: {
			line: {
				marker: {
					enabled: false,
				},
			},
		},
		navigator: {
			enabled: true,
			xAxis: {
				min: xMin,
				max: xMax,
			},
		},
		scrollbar: {
			enabled: true,
			liveRedraw: true,
		},
		rangeSelector: {
			selected:
				props.place.play_status !== ExperienceStatus.PLAYABLE ? 7 : 2,
		},
	};

	const updateTimeFrame = (timeframe: PlaceStatGranularity) => {
		void getData(timeframe);
		setGranularity(timeframe);
		setLoading(true);
	};

	useEffect(() => {
		void getData(granularity);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className={'w-full'}>
			<div className={'w-full relative'}>
				<Chart
					highcharts={Highcharts}
					options={options}
					granularity={granularity}
				/>

				{loading && (
					<LoadingIndicator
						className={
							'!absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
						}
					/>
				)}
				{noData && (
					<div
						className={
							'absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
						}
					>
						No Data
					</div>
				)}
			</div>

			<div
				className={
					'w-full flex items-center justify-between flex-wrap gap-4'
				}
			>
				<GranularitySelector
					updateGranularity={updateTimeFrame}
					selectedGranularity={granularity}
				/>

				<div
					className={
						'flex justify-center items-center gap-4 select-none'
					}
				>
					<div
						className={
							'flex flex-col justify-center items-center gap-4'
						}
					>
						<div className={'text-xs'}>{'Thumbnails'}</div>
						<SlideToggle
							initialState={true}
							smallButton
							icons={{
								on: IconEnum.CONFIRM,
								onSize: '1.5rem',
								off: IconEnum.CLEAR,
								offSize: '1.5rem',
							}}
							onColor={
								ThemeStore.lightTheme
									? BrandColors.green600
									: BrandColors.green500
							}
							offColor={
								ThemeStore.lightTheme
									? BrandColors.red600
									: BrandColors.red400
							}
							onToggle={(show) => {
								setShowThumbnails(show);
							}}
						/>
					</div>

					<div
						className={
							'flex flex-col justify-center items-center gap-4'
						}
					>
						<div className={'text-xs'}>{'Banners'}</div>
						<SlideToggle
							initialState={true}
							smallButton
							icons={{
								on: IconEnum.CONFIRM,
								onSize: '1.5rem',
								off: IconEnum.CLEAR,
								offSize: '1.5rem',
							}}
							onColor={
								ThemeStore.lightTheme
									? BrandColors.green600
									: BrandColors.green500
							}
							offColor={
								ThemeStore.lightTheme
									? BrandColors.red600
									: BrandColors.red400
							}
							onToggle={(show) => {
								setShowBanners(show);
							}}
						/>
					</div>
				</div>
			</div>
		</div>
	);
});

export default ExperienceCcuAndVisits;
